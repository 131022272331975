
import { Award } from 'lucide-react';

const subscriptionTiers = [
  {
    type: 'Basic',
    monthlyFee: 9.99,
    features: ['Emergency Access'],
    annualValue: 119.88,
    description: 'The "sleep well" package for homeowners'
  },
  {
    type: 'Premium',
    monthlyFee: 49.99,
    features: ['Priority Response'],
    annualValue: 599.88,
    description: 'The "never worry" solution for property managers'
  },
  {
    type: 'Enterprise',
    monthlyFee: 199.99,
    features: ['Custom Integration'],
    annualValue: 2399.88,
    description: 'The "total control" platform for real estate portfolios'
  }
];

export function SubscriptionTiersCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Award className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Subscription Tiers</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {subscriptionTiers.map((tier) => (
          <div
            key={tier.type}
            className="border border-gray-200 rounded-lg p-6 hover:border-primary transition-colors"
          >
            <h3 className="text-lg font-semibold text-gray-900 mb-2">{tier.type}</h3>
            <div className="mb-4">
              <span className="text-3xl font-bold text-primary">${tier.monthlyFee}</span>
              <span className="text-gray-500">/month</span>
            </div>
            <ul className="mb-4 space-y-2">
              {tier.features.map((feature, index) => (
                <li key={index} className="flex items-start text-gray-600">
                  <span className="text-primary mr-2">•</span>
                  {feature}
                </li>
              ))}
            </ul>
            <p className="text-sm text-gray-500 mb-4">
              Annual value: ${tier.annualValue}
            </p>
            <p className="text-sm text-gray-600 italic">
              {tier.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}