import { Code } from 'lucide-react';

const developmentCosts = [
  { 
    component: 'Personal Time Investment', 
    monthlyCost: 0, 
    annualCost: 0, 
    notes: '20 hours/week outside day job' 
  },
  { 
    component: 'Vercel Infrastructure', 
    monthlyCost: 150, 
    annualCost: 1800, 
    notes: 'Pay-as-you-go model' 
  },
  { 
    component: 'AI API Costs', 
    monthlyCost: 200, 
    annualCost: 2400, 
    notes: 'OpenAI API usage' 
  },
  { 
    component: 'Development Tools', 
    monthlyCost: 50, 
    annualCost: 600, 
    notes: 'GitHub, IDE, etc.' 
  },
  { 
    component: 'Security & Compliance', 
    monthlyCost: 100, 
    annualCost: 1200, 
    notes: 'Certificates, audits' 
  },
  { 
    component: 'Lambda Functions', 
    monthlyCost: 40, 
    annualCost: 480, 
    notes: 'Serverless compute' 
  },
  { 
    component: 'API Gateway', 
    monthlyCost: 15, 
    annualCost: 180, 
    notes: 'API management' 
  },
  { 
    component: 'DynamoDB', 
    monthlyCost: 25, 
    annualCost: 300, 
    notes: 'Database' 
  },
  { 
    component: 'S3 Storage', 
    monthlyCost: 10, 
    annualCost: 120, 
    notes: 'File storage' 
  },
  { 
    component: 'CloudFront', 
    monthlyCost: 15, 
    annualCost: 180, 
    notes: 'CDN' 
  },
  { 
    component: 'Route 53', 
    monthlyCost: 5, 
    annualCost: 60, 
    notes: 'Domain management' 
  },
  { 
    component: 'CloudWatch', 
    monthlyCost: 20, 
    annualCost: 240, 
    notes: 'Logs & metrics' 
  },
  { 
    component: 'SES', 
    monthlyCost: 10, 
    annualCost: 120, 
    notes: 'Email service' 
  },
  { 
    component: 'SNS', 
    monthlyCost: 10, 
    annualCost: 120, 
    notes: 'Notifications' 
  }
];

export function DevelopmentCostsCard() {
  const totalMonthlyCost = developmentCosts.reduce((acc, curr) => acc + curr.monthlyCost, 0);
  const totalAnnualCost = developmentCosts.reduce((acc, curr) => acc + curr.annualCost, 0);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Code className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Development Costs (Year 1)</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Component
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Monthly Cost
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Annual Cost
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Notes
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {developmentCosts.map((cost) => (
              <tr key={cost.component}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {cost.component}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${cost.monthlyCost}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${cost.annualCost}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {cost.notes}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Total
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                ${totalMonthlyCost}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                ${totalAnnualCost}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
} 