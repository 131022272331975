
import { Settings } from 'lucide-react';

const strategies = [
  {
    strategy: 'Response Caching',
    implementation: 'Redis Cache',
    savings: '15-20%',
    timeline: 'Month 1'
  },
  {
    strategy: 'Request Batching',
    implementation: 'Queue System',
    savings: '10-15%',
    timeline: 'Month 2'
  },
  {
    strategy: 'Prompt Compression',
    implementation: 'Template Engine',
    savings: '20-25%',
    timeline: 'Month 1'
  },
  {
    strategy: 'Load Balancing',
    implementation: 'Auto-scaling',
    savings: '15-20%',
    timeline: 'Month 3'
  }
];

export function OptimizationStrategiesCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Settings className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Cost Optimization Strategies</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Strategy
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Implementation
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Expected Savings
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Timeline
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {strategies.map((strategy) => (
              <tr key={strategy.strategy}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {strategy.strategy}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {strategy.implementation}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {strategy.savings}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {strategy.timeline}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}