
import { Home } from 'lucide-react';

interface TeamMember {
  name: string;
  role: string;
  responsibilities: string[];
}

export function PropertyServices() {
  const propertyTeam: TeamMember[] = [
    {
      name: 'Matthieu Cardinal - Persona AI Adivisor',
      role: 'Lead Property Expert',
      responsibilities: [
        'Property management standards',
        'Service provider protocols',
        'Quality assurance'
      ]
    },
    {
      name: 'Barbara Corcoran - Persona AI Advisor',
      role: 'Strategic Advisor',
      responsibilities: [
        'Growth strategy',
        'Market positioning',
        'Service optimization'
      ]
    },
    {
      name: 'Rich Barton - Persona AI Advisor',
      role: 'Platform Development Lead',
      responsibilities: [
        'Technology integration',
        'Service platform design',
        'User experience'
      ]
    },
    {
      name: 'Robert Reffkin - Persona AI Advisor',
      role: 'Operations Technology Lead',
      responsibilities: [
        'Service provider systems',
        'Operational efficiency',
        'Technology implementation'
      ]
    },
    {
      name: 'Eric Wu - Persona AI Advisor',
      role: 'Automation Expert',
      responsibilities: [
        'Process automation',
        'System optimization',
        'Operational efficiency'
      ]
    }
  ];

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-3 mb-8">
        <Home className="h-8 w-8 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Property Services Division</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {propertyTeam.map((member) => (
          <div
            key={member.name}
            className="bg-white rounded-lg shadow-sm p-6"
          >
            <h3 className="text-lg font-semibold text-gray-900">{member.name}</h3>
            <p className="text-sm text-blue-600 mb-3">{member.role}</p>
            <ul className="space-y-1">
              {member.responsibilities.map((resp, index) => (
                <li key={index} className="text-sm text-gray-600 flex items-start">
                  <span className="mr-2 text-blue-600">•</span>
                  {resp}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}