import { Headphones } from 'lucide-react';
import { TeamSection } from './TeamSection';
import { TeamMember } from './types';

export function CustomerExperience() {
  const customerTeam: TeamMember[] = [
    {
      name: 'Ann Handley - Persona AI Advisor',
      role: 'Content Strategy Lead',
      responsibilities: [
        'Content development',
        'Communication standards',
        'Documentation'
      ],
      image: '/team/customer/ann-handley.jpg'
    },
    {
      name: 'Shep Hyken - Persona AI Advisor',
      role: 'Customer Service Lead',
      responsibilities: [
        'Service excellence',
        'Customer satisfaction',
        'Experience optimization'
      ],
      image: '/team/customer/shep-hyken.jpg'
    },
    {
      name: 'Blake Morgan - Persona AI Advisor',
      role: 'Experience Design Lead',
      responsibilities: [
        'Experience design',
        'Customer journey',
        'Service innovation'
      ],
      image: '/team/customer/blake-morgan.jpg'
    },
    {
      name: 'Dan Gingiss - Persona AI Advisor',
      role: 'Social Experience Lead',
      responsibilities: [
        'Social engagement',
        'Customer feedback',
        'Community building'
      ],
      image: '/team/customer/dan-gingiss.jpg'
    }
  ];

  return <TeamSection title="Customer Experience" icon={Headphones} members={customerTeam} />;
}