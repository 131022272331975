import { Brain } from 'lucide-react';
import { TeamSection } from './TeamSection';
import { TeamMember } from './types';

export function AITeam() {
  const aiTeam: TeamMember[] = [
    {
      name: 'Sam Altman - Persona AI Advisor',
      role: 'OpenAI Strategic Advisor',
      responsibilities: [
        'AI strategy',
        'Model selection',
        'Ethics oversight'
      ],
      image: '/team/ai/sam-altman.jpg'
    },
    {
      name: 'Mira Murati - Persona AI Advisor',
      role: 'Technical Integration Advisor',
      responsibilities: [
        'AI implementation',
        'System integration',
        'Performance optimization'
      ],
      image: '/team/ai/mira-murati.jpg'
    },
    {
      name: 'Timnit Gebru - Persona AI Advisor',
      role: 'AI Ethics Lead',
      responsibilities: [
        'Ethical AI implementation',
        'Bias prevention',
        'Fairness assessment'
      ],
      image: '/team/ai/timnit-gebru.jpg'
    },
    {
      name: 'Margaret Mitchell - Persona AI Advisor',
      role: 'Ethics Implementation',
      responsibilities: [
        'Ethical guidelines',
        'Implementation standards',
        'Bias monitoring'
      ],
      image: '/team/ai/margaret-mitchell.jpg'
    },
    {
      name: 'Jeff Lawson - Persona AI Advisor',
      role: 'Twilio Integration Lead',
      responsibilities: [
        'Communication architecture',
        'IVR system design',
        'Platform integration'
      ],
      image: '/team/ai/jeff-lawson.jpg'
    },
    {
      name: 'Quinton Wall - Persona AI Advisor',
      role: 'Platform Strategy Director',
      responsibilities: [
        'API implementation',
        'Communication workflows',
        'System integration'
      ],
      image: '/team/ai/quinton-wall.jpg'
    }
  ];

  return <TeamSection title="AI & Integration" icon={Brain} members={aiTeam} />;
}