import { Mail, Linkedin, Globe } from 'lucide-react';
import { memo } from 'react';
import { TeamMember, TeamSectionProps } from './types';

const MemoizedTeamMemberCard = memo(({ member }: { member: TeamMember }) => (
  <div className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow">
    <div className="p-6">
      <div className="flex items-start space-x-4">
        <img
          src={member.image}
          alt={member.name}
          className="w-20 h-20 rounded-full object-cover"
          onError={(e) => {
            e.currentTarget.src = '/placeholder-avatar.png';
          }}
        />
        <div className="flex-1 min-w-0">
          <h3 className="text-lg font-semibold text-gray-900 truncate">
            {member.name}
          </h3>
          <p className="text-sm text-blue-600 mb-2">{member.role}</p>
          <div className="space-y-1">
            {member.responsibilities.map((resp, index) => (
              <p key={index} className="text-sm text-gray-600 flex items-start">
                <span className="mr-2 text-blue-600">•</span>
                {resp}
              </p>
            ))}
          </div>
          {member.contact && (
            <div className="mt-4 flex space-x-4">
              {member.contact.email && (
                <a
                  href={`mailto:${member.contact.email}`}
                  className="text-gray-500 hover:text-blue-600"
                  aria-label="Email"
                >
                  <Mail className="h-5 w-5" />
                </a>
              )}
              {member.contact.linkedin && (
                <a
                  href={member.contact.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-blue-600"
                  aria-label="LinkedIn"
                >
                  <Linkedin className="h-5 w-5" />
                </a>
              )}
              {member.contact.website && (
                <a
                  href={member.contact.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-blue-600"
                  aria-label="Website"
                >
                  <Globe className="h-5 w-5" />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
));

MemoizedTeamMemberCard.displayName = 'TeamMemberCard';

export function TeamSection({ title, icon: Icon, members }: TeamSectionProps) {
  return (
    <div className="mb-16">
      <div className="flex items-center gap-3 mb-8">
        <Icon className="h-8 w-8 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {members.map((member) => (
          <MemoizedTeamMemberCard key={member.name} member={member} />
        ))}
      </div>
    </div>
  );
}