import { DollarSign } from 'lucide-react';

const costKPIs = [
  {
    kpi: 'Cost per Query',
    target: '$0.02',
    alertThreshold: '$0.025',
    owner: 'Werner Vogels',
    status: 'On Track'
  },
  {
    kpi: 'Daily Token Usage',
    target: '85%',
    alertThreshold: '90%',
    owner: 'DJ Patil',
    status: 'Warning'
  },
  {
    kpi: 'Cache Hit Rate',
    target: '40%',
    alertThreshold: '30%',
    owner: 'Anthony Shaw',
    status: 'On Track'
  },
  {
    kpi: 'Error Rate',
    target: '1%',
    alertThreshold: '2%',
    owner: 'Timnit Gebru',
    status: 'Critical'
  }
];

export function CostMetricsCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <DollarSign className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Cost Monitoring KPIs</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                KPI
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Target
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Alert Threshold
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Owner
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {costKPIs.map((kpi) => (
              <tr key={kpi.kpi}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {kpi.kpi}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {kpi.target}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {kpi.alertThreshold}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {kpi.owner}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    kpi.status === 'On Track' 
                      ? 'bg-green-100 text-green-800'
                      : kpi.status === 'Warning'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {kpi.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
} 