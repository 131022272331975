'use client';

import { Server } from 'lucide-react';
import {
  DevelopmentCostsCard,
  OperatingExpensesCard,
  MarketingAllocationCard,
  StaffingTimelineCard,
  OptimizationStrategiesCard
} from './cards';

export function DetailedCostBreakdown() {
  return (
    <div className="space-y-8">
      <div className="mb-8">
        <div className="flex items-center gap-3 mb-4">
          <Server className="h-6 w-6 text-primary" />
          <h1 className="text-3xl font-bold text-gray-900">Detailed Cost Breakdown</h1>
        </div>
        <p className="text-lg text-gray-600">
          Comprehensive analysis of all operational and development costs.
        </p>
      </div>

      {/* Development Costs */}
      <DevelopmentCostsCard />

      {/* Operating Expenses */}
      <OperatingExpensesCard />

      {/* Marketing Budget */}
      <MarketingAllocationCard />

      {/* Staffing Timeline */}
      <StaffingTimelineCard />

      {/* Optimization Strategies */}
      <OptimizationStrategiesCard />
    </div>
  );
}