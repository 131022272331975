
import { Brain, BarChart, Cpu, Calculator } from 'lucide-react';

interface AnalysisComponent {
  title: string;
  items: string[];
  icon: React.ElementType;
}

export function AIStrategySection() {
  const analysisComponents: AnalysisComponent[] = [
    {
      title: 'Business Strategy Simulation',
      icon: BarChart,
      items: [
        'Market expansion modeling',
        'Growth trajectory analysis',
        'Partnership opportunity identification',
        'Risk assessment frameworks'
      ]
    },
    {
      title: 'Technical Implementation Simulation',
      icon: Cpu,
      items: [
        'Architecture optimization scenarios',
        'Scaling requirement projections',
        'Integration strategy modeling',
        'Performance metric predictions'
      ]
    },
    {
      title: 'Operations & Financial Modeling',
      icon: Calculator,
      items: [
        'Network efficiency modeling',
        'Resource allocation optimization',
        'Revenue projection models',
        'Risk-adjusted forecasting'
      ]
    }
  ];

  return (
    <div className="mb-16">
      <div className="flex items-center gap-3 mb-6">
        <Brain className="h-8 w-8 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">AI-Powered Strategic Analysis Platform</h2>
      </div>

      <p className="text-gray-600 mb-8">
        ServiConnect utilizes an advanced AI system for strategic planning and analysis. This system
        analyzes market data, industry patterns, and business metrics to generate data-driven insights
        and recommendations while simulating different leadership perspectives.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {analysisComponents.map((component) => {
          const Icon = component.icon;
          return (
            <div key={component.title} className="bg-white rounded-lg shadow-sm p-6">
              <Icon className="h-6 w-6 text-blue-600 mb-4" />
              <h3 className="text-lg font-semibold text-gray-900 mb-4">{component.title}</h3>
              <ul className="space-y-2">
                {component.items.map((item, index) => (
                  <li key={index} className="text-sm text-gray-600 flex items-start">
                    <span className="mr-2 text-blue-600">•</span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}