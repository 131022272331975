
import { BookOpen } from 'lucide-react';

export function AdvisoryBoard() {
  const advisors = [
    {
      name: 'Paul Polman',
      role: 'Sustainability Advisor',
      responsibilities: [
        'Environmental impact',
        'Sustainable practices',
        'Social responsibility'
      ]
    },
    {
      name: 'Arlan Hamilton',
      role: 'Board Member',
      responsibilities: [
        'Diversity initiatives',
        'Inclusive growth',
        'Strategic planning'
      ]
    },
    {
      name: 'Oren Klaff',
      role: 'Strategic Planning Advisor',
      responsibilities: [
        'Growth strategy',
        'Market positioning',
        'Business development'
      ]
    }
  ];

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-3 mb-8">
        <BookOpen className="h-8 w-8 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Advisory Board</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {advisors.map((advisor) => (
          <div
            key={advisor.name}
            className="bg-white rounded-lg shadow-sm p-6"
          >
            <h3 className="text-lg font-semibold text-gray-900">{advisor.name}</h3>
            <p className="text-sm text-blue-600 mb-3">{advisor.role}</p>
            <ul className="space-y-1">
              {advisor.responsibilities.map((resp, index) => (
                <li key={index} className="text-sm text-gray-600 flex items-start">
                  <span className="mr-2 text-blue-600">•</span>
                  {resp}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}