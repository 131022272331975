
import { Shield, AlertTriangle, Lock, BarChart2 } from 'lucide-react';
import { FinancialRiskMatrixCard } from './cards/FinancialRiskMatrixCard';
import { OperationalContingenciesCard } from './cards/OperationalContingenciesCard';
import { InsuranceComplianceCard } from './cards/InsuranceComplianceCard';
import { AIRiskManagementCard } from './cards/AIRiskManagementCard';

export function RiskMitigation() {
  return (
    <div className="space-y-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Risk Analysis & Mitigation</h1>
        <p className="text-lg text-gray-600">
          Comprehensive risk management strategy and contingency planning.
        </p>
      </div>

      {/* Financial Risk Matrix */}
      <FinancialRiskMatrixCard />

      {/* Operational Contingencies */}
      <OperationalContingenciesCard />

      {/* Insurance & Compliance */}
      <InsuranceComplianceCard />

      {/* AI Risk Management */}
      <AIRiskManagementCard />
    </div>
  );
}