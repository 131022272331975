import { DollarSign, TrendingUp } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface CashFlowData {
  month: string;
  revenue: number;
  expenses: number;
  cash: number;
}

export function CashFlowProjectionCard() {
  // Generate 18 months of cash flow projections
  const generateCashFlowData = (): CashFlowData[] => {
    const data: CashFlowData[] = [];
    let cash = 2500000; // Initial seed round
    
    for (let i = 0; i < 18; i++) {
      const month = `M${i + 1}`;
      
      // Revenue calculation with growth
      const baseRevenue = i === 0 ? 10000 : data[i-1].revenue;
      const revenue = i === 0 ? baseRevenue : baseRevenue * (1 + 0.15); // 15% monthly growth
      
      // Expense calculation
      const baseExpense = 120000; // Base monthly burn
      const variableExpense = revenue * 0.3; // 30% variable costs
      const expenses = baseExpense + variableExpense;
      
      // Update cash position
      cash = cash + revenue - expenses;
      
      data.push({
        month,
        revenue: Math.round(revenue),
        expenses: Math.round(expenses),
        cash: Math.round(cash)
      });
    }
    
    return data;
  };

  const cashFlowData = generateCashFlowData();
  
  const keyMetrics = [
    {
      label: 'Initial Runway',
      value: '18 months',
      description: 'Based on current burn rate'
    },
    {
      label: 'Monthly Burn Rate',
      value: '$120K',
      description: 'Base operational costs'
    },
    {
      label: 'Break-even Point',
      value: 'Month 15',
      description: 'Projected timeline'
    },
    {
      label: 'Minimum Cash Reserve',
      value: '$500K',
      description: 'Strategic contingency'
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <DollarSign className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Cash Flow Projections</h2>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {keyMetrics.map((metric) => (
          <div key={metric.label} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-1">{metric.label}</h3>
            <p className="text-2xl font-bold text-gray-900 mb-1">{metric.value}</p>
            <p className="text-sm text-gray-600">{metric.description}</p>
          </div>
        ))}
      </div>

      {/* Cash Flow Chart */}
      <div className="h-80 mb-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={cashFlowData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip 
              formatter={(value: number) => new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value)}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="revenue"
              name="Revenue"
              stroke="#2A9D8F"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="expenses"
              name="Expenses"
              stroke="#E63946"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="cash"
              name="Cash Position"
              stroke="#457B9D"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Monthly Breakdown */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Revenue</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expenses</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cash Position</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {cashFlowData.map((month) => (
              <tr key={month.month}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{month.month}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(month.revenue)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(month.expenses)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(month.cash)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
