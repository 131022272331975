'use client';

import { TrendingUp, DollarSign } from 'lucide-react';

interface MetricProps {
  label: string;
  current: string;
  target: string;
  trend: 'up' | 'down' | 'stable';
}

interface UnitEconomicsDeepDiveCardProps {
  metrics: {
    metric: string;
    current: number | string;
    targetY1: number | string;
    targetY2: number | string;
    trend: 'increasing' | 'decreasing' | 'stable';
  }[];
}

export function UnitEconomicsDeepDiveCard({ metrics }: UnitEconomicsDeepDiveCardProps) {
  const premiumMetrics: MetricProps[] = [
    {
      label: 'Average Revenue Per Provider',
      current: '$2,500/month',
      target: '$3,500/month',
      trend: 'up'
    },
    {
      label: 'Provider Acquisition Cost',
      current: '$200',
      target: '$180',
      trend: 'down'
    },
    {
      label: 'Provider Lifetime Value',
      current: '$45,000',
      target: '$63,000',
      trend: 'up'
    },
    {
      label: 'Gross Margin',
      current: '70%',
      target: '75%',
      trend: 'up'
    }
  ];

  const qualityMetrics: MetricProps[] = [
    {
      label: 'Response Time SLA',
      current: '35 min',
      target: '30 min',
      trend: 'down'
    },
    {
      label: 'Customer Satisfaction',
      current: '88%',
      target: '90%',
      trend: 'up'
    },
    {
      label: 'Provider Rating',
      current: '4.6/5',
      target: '4.8/5',
      trend: 'up'
    },
    {
      label: 'Retention Rate',
      current: '85%',
      target: '90%',
      trend: 'up'
    }
  ];

  const operationalMetrics: MetricProps[] = [
    {
      label: 'AI Match Accuracy',
      current: '92%',
      target: '95%',
      trend: 'up'
    },
    {
      label: 'Platform Uptime',
      current: '99.5%',
      target: '99.9%',
      trend: 'up'
    },
    {
      label: 'Insurance Claim Rate',
      current: '0.5%',
      target: '0.3%',
      trend: 'down'
    },
    {
      label: 'Average Service Time',
      current: '2.5 hours',
      target: '2 hours',
      trend: 'down'
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Premium Service Economics</h2>
      </div>

      <div className="space-y-8">
        {/* Premium Provider Metrics */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Premium Provider Economics</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {premiumMetrics.map((metric) => (
              <MetricCard key={metric.label} {...metric} />
            ))}
          </div>
        </div>

        {/* Quality Metrics */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Quality Metrics</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {qualityMetrics.map((metric) => (
              <MetricCard key={metric.label} {...metric} />
            ))}
          </div>
        </div>

        {/* Operational Metrics */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Operational Excellence</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {operationalMetrics.map((metric) => (
              <MetricCard key={metric.label} {...metric} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function MetricCard({ label, current, target, trend }: MetricProps) {
  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <h4 className="text-sm font-medium text-gray-500 mb-2">{label}</h4>
      <div className="flex justify-between items-end">
        <div>
          <p className="text-lg font-bold text-gray-900">{current}</p>
          <p className="text-sm text-gray-600">Target: {target}</p>
        </div>
        <div className={`text-sm ${trend === 'up' ? 'text-green-500' : trend === 'down' ? 'text-red-500' : 'text-gray-500'}`}>
          {trend === 'up' ? '↑' : trend === 'down' ? '↓' : '→'}
        </div>
      </div>
    </div>
  );
}