import { Shield } from 'lucide-react';
import type { EmergencyTrigger } from '../../../types/risk';

const emergencyFundTriggers: EmergencyTrigger[] = [
  {
    trigger: 'Revenue Miss > 20%',
    action: 'Marketing Budget Adjustment',
    owner: 'Sheryl Sandberg'
  },
  {
    trigger: 'Provider Shortage',
    action: 'Incentive Program Activation',
    owner: 'DJ Patil'
  },
  {
    trigger: 'Technical Issues',
    action: 'Emergency Development Fund',
    owner: 'Werner Vogels'
  },
  {
    trigger: 'Market Changes',
    action: 'Strategy Pivot Fund',
    owner: 'Satya Nadella'
  }
];

const technicalTriggers: EmergencyTrigger[] = [
  {
    trigger: 'AI System Failure',
    action: 'Fallback to Rule-Based System',
    owner: 'Werner Vogels'
  },
  {
    trigger: 'Database Issues',
    action: 'Automatic Failover Protocol',
    owner: 'Craig Kerstiens'
  },
  {
    trigger: 'API Overload',
    action: 'Load Balancing Activation',
    owner: 'Anthony Shaw'
  },
  {
    trigger: 'Security Breach',
    action: 'Incident Response Plan',
    owner: 'Justin Seitz'
  }
];

export function OperationalContingenciesCard() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center mb-4">
        <Shield className="w-6 h-6 text-blue-500 mr-2" />
        <h2 className="text-xl font-semibold text-gray-900">Operational Contingencies</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">Emergency Fund Deployment</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Trigger
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Owner
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {emergencyFundTriggers.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{item.trigger}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{item.action}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{item.owner}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">Technical Response Protocol</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Trigger
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Owner
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {technicalTriggers.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{item.trigger}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{item.action}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{item.owner}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}