import { createBrowserRouter, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSession } from '@supabase/auth-helpers-react';
import { useState, useEffect } from 'react';
import { supabase } from './lib/supabase';
import { toast } from 'react-hot-toast';
import { Presentation } from './components/Presentation';
import { BusinessPlan } from './components/BusinessPlan';
import { FinancialDashboard } from './components/financials/FinancialDashboard';
import { BrandIdentity } from './components/BrandIdentity';
import { Team } from './components/Team';
import { Documentation } from './components/Documentation';
import { Settings } from './components/Settings';
import { Profile } from './components/Profile';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { AcceptInvitation } from './components/auth/AcceptInvitation';
import { Admin } from './components/Admin';
import { DashboardLayout } from './components/layouts/DashboardLayout';
import { FloatingChat } from './components/FloatingChat';
import { UserManagement } from './components/admin/UserManagement';
import { AdminSettings } from './components/admin/AdminSettings';

function ProtectedRoute() {
  const session = useSession();
  
  if (!session) {
    return <Navigate to="/login" replace />;
  }
  
  return (
    <>
      <Outlet />
      <FloatingChat />
    </>
  );
}

function AdminRoute() {
  const session = useSession();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function checkAdminAccess() {
      if (!session?.user?.id) {
        navigate('/login');
        return;
      }

      try {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', session.user.id)
          .single();

        if (profileError || !profile || profile.role !== 'admin') {
          navigate('/dashboard');
          toast.error('Unauthorized: Requires admin access');
          return;
        }
      } catch (error) {
        console.error('Access check failed:', error);
        navigate('/dashboard');
      } finally {
        setIsLoading(false);
      }
    }

    checkAdminAccess();
  }, [session, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
}

const isDevelopment = process.env.NODE_ENV === 'development';

export const router = createBrowserRouter([
  {
    path: '/accept-invitation',
    element: <AcceptInvitation />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <Presentation />,
          },
          {
            path: 'business-plan',
            element: <BusinessPlan />,
          },
          {
            path: 'financials',
            element: <FinancialDashboard />,
          },
          {
            path: 'brand-identity',
            element: <BrandIdentity />,
          },
          {
            path: 'team',
            element: <Team />,
          },
          {
            path: 'documentation',
            element: <Documentation />,
          },
        ],
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'admin',
        element: <AdminRoute />,
        children: [
          {
            index: true,
            element: <Admin />,
          },
          {
            path: 'users',
            element: <UserManagement users={[]} onRefresh={async () => Promise.resolve()} />,
          },
          {
            path: 'settings',
            element: <AdminSettings />,
          },
        ],
      },
    ],
  },
], {
  basename: '/',
});
