'use client';

import { Target } from 'lucide-react';

interface MilestoneData {
  round: string;
  amount: string;
  goals: string[];
}

interface InvestmentMilestonesCardProps {
  milestones: MilestoneData[];
}

export function InvestmentMilestonesCard({ milestones }: InvestmentMilestonesCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Target className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Investment Milestones</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {milestones.map((milestone) => (
          <div key={milestone.round} className="bg-gray-50 rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">{milestone.round}</h3>
              <span className="text-primary font-semibold">{milestone.amount}</span>
            </div>
            <ul className="space-y-2">
              {milestone.goals.map((goal, index) => (
                <li key={index} className="flex items-start text-gray-600">
                  <span className="text-primary mr-2">•</span>
                  {goal}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}