
import { MapPin } from 'lucide-react';

const marketEntryData = [
  {
    region: 'Montreal',
    initialInvestment: 100000,
    marketingBudget: 25000,
    expectedCAC: 200,
    breakEvenTimeline: 6
  },
  {
    region: 'Quebec City',
    initialInvestment: 75000,
    marketingBudget: 20000,
    expectedCAC: 180,
    breakEvenTimeline: 5
  },
  {
    region: 'Laval',
    initialInvestment: 50000,
    marketingBudget: 15000,
    expectedCAC: 160,
    breakEvenTimeline: 4
  }
];

export function MarketEntryCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <MapPin className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Market Entry Strategy</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Region
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Initial Investment
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Marketing Budget
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Expected CAC
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Break-Even Timeline
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {marketEntryData.map((entry) => (
              <tr key={entry.region}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {entry.region}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${entry.initialInvestment.toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${entry.marketingBudget.toLocaleString()}/month
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${entry.expectedCAC}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {entry.breakEvenTimeline} months
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}