
import { DollarSign } from 'lucide-react';

const serviceFees = [
  {
    level: 'Critical',
    responseTime: '15-30min',
    baseFee: 250,
    aiPremium: 15,
    providerShare: 70,
    netRevenue: 86.25
  },
  {
    level: 'Urgent',
    responseTime: '30-60min',
    baseFee: 175,
    aiPremium: 12,
    providerShare: 75,
    netRevenue: 49.00
  },
  {
    level: 'Standard',
    responseTime: '2-4hrs',
    baseFee: 100,
    aiPremium: 10,
    providerShare: 80,
    netRevenue: 22.00
  }
];

const aiImprovements = [
  'Reduce response times by 45% through smart provider matching',
  'Decrease misdiagnosis rates by 60% through AI-powered triage',
  'Improve first-time resolution rates by 35%'
];

export function ServiceFeesCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <DollarSign className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Emergency Service Fees</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Service Level
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Response Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Base Fee
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                AI Premium
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Provider Share
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Net Revenue
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {serviceFees.map((fee) => (
              <tr key={fee.level}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {fee.level}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {fee.responseTime}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${fee.baseFee}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  +{fee.aiPremium}%
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {fee.providerShare}%
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${fee.netRevenue}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-medium text-gray-900 mb-3">AI Premium Benefits</h3>
        <ul className="space-y-2">
          {aiImprovements.map((improvement, index) => (
            <li key={index} className="flex items-start text-gray-600">
              <span className="text-primary mr-2">•</span>
              {improvement}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}