import { ForecastDataPoint } from '../types/financial';

export const calculateNetPosition = (data: ForecastDataPoint): number => {
  return data.revenue + data.development + data.marketing;
};

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(Math.abs(value));
};

export const calculateGrowthRate = (current: number, previous: number): number => {
  if (previous === 0) return 0;
  return ((current - previous) / Math.abs(previous)) * 100;
};

export const getChartColors = {
  development: '#003366',
  marketing: '#4D9DE0',
  revenue: '#2A9D8F',
  netPosition: '#E63946'
}; 