'use client';

import { Brain } from 'lucide-react';
import {
  TokenProjectionsCard,
  CostMetricsCard,
  OptimizationStrategiesCard,
  ScalingThresholdsCard,
  EmergencyFallbackCard
} from './cards';

export function AICostOptimization() {
  return (
    <div className="space-y-8">
      <div className="mb-8">
        <div className="flex items-center gap-3 mb-4">
          <Brain className="h-6 w-6 text-primary" />
          <h1 className="text-3xl font-bold text-gray-900">AI Cost Optimization</h1>
        </div>
        <p className="text-lg text-gray-600">
          Strategic approach to managing and optimizing AI infrastructure costs while maintaining service quality.
        </p>
      </div>

      {/* Token Usage Projections */}
      <TokenProjectionsCard />

      {/* Cost Metrics */}
      <CostMetricsCard />

      {/* Optimization Strategies */}
      <OptimizationStrategiesCard />

      {/* Scaling Thresholds */}
      <ScalingThresholdsCard />

      {/* Emergency Fallback */}
      <EmergencyFallbackCard />
    </div>
  );
}