'use client';

import { GrowthMetricsCard } from './cards/GrowthMetricsCard';
import { InvestmentMilestonesCard } from './cards/InvestmentMilestonesCard';
import { UnitEconomicsDeepDiveCard } from './cards/UnitEconomicsDeepDiveCard';
import { TrendingUp } from 'lucide-react';

interface MetricData {
  label: string;
  value: string;
  trend?: 'up' | 'down' | 'stable';
}

interface MilestoneData {
  round: string;
  amount: string;
  goals: string[];
}

interface UnitEconomics {
  metric: string;
  current: number | string;
  targetY1: number | string;
  targetY2: number | string;
  trend: 'increasing' | 'decreasing' | 'stable';
}

interface RecommendationSection {
  title: string;
  items: string[];
}

export function InvestorMetrics() {
  const growthMetrics: MetricData[] = [
    { label: 'Monthly Active Providers', value: '25 premium providers', trend: 'up' },
    { label: 'Customer Retention', value: '90% target', trend: 'up' },
    { label: 'Response Time', value: '30 minutes', trend: 'stable' },
    { label: 'Service Quality', value: '4.8/5 target', trend: 'up' }
  ];

  const investmentMilestones: MilestoneData[] = [
    {
      round: 'Seed Round',
      amount: '$2.5M',
      goals: [
        'Premium Provider Network (25)',
        'AI Infrastructure Foundation',
        'Insurance Partner Integration',
        '70% Gross Margin Achievement'
      ]
    },
    {
      round: 'Series A Target',
      amount: '$5M+',
      goals: [
        'Market Expansion Beyond Montreal',
        'Advanced AI Capabilities',
        '100+ Premium Providers',
        '$10M+ Annual Revenue Run Rate'
      ]
    }
  ];

  const unitEconomics: UnitEconomics[] = [
    {
      metric: 'CAC',
      current: 200,
      targetY1: 180,
      targetY2: 150,
      trend: 'decreasing'
    },
    {
      metric: 'LTV',
      current: 600,
      targetY1: 900,
      targetY2: 1500,
      trend: 'increasing'
    },
    {
      metric: 'Gross Margin',
      current: '70%',
      targetY1: '75%',
      targetY2: '78%',
      trend: 'increasing'
    },
    {
      metric: 'Provider Retention',
      current: '85%',
      targetY1: '90%',
      targetY2: '95%',
      trend: 'increasing'
    }
  ];

  const recommendations: RecommendationSection[] = [
    {
      title: 'Investment Allocation Strategy',
      items: [
        'Technology/AI Infrastructure: 40%',
        'Provider Acquisition & Retention: 30%',
        'Operations & Team: 20%',
        'Strategic Contingency: 10%'
      ]
    },
    {
      title: 'Key Performance Indicators',
      items: [
        'Provider Quality Score (4.8+ target)',
        'Response Time (30-minute SLA)',
        'Customer Satisfaction (90%+)',
        'Platform Reliability (99.9%)'
      ]
    }
  ];

  return (
    <div className="space-y-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Key Investor Metrics</h1>
        <p className="text-lg text-gray-600">
          Essential metrics and milestones demonstrating ServiConnect's growth trajectory and investment potential.
        </p>
      </div>

      {/* Growth Metrics */}
      <div className="grid grid-cols-1 gap-8">
        <GrowthMetricsCard metrics={growthMetrics} />
      </div>

      {/* Investment Milestones */}
      <div className="grid grid-cols-1 gap-8">
        <InvestmentMilestonesCard milestones={investmentMilestones} />
      </div>

      {/* Unit Economics Deep Dive */}
      <div className="grid grid-cols-1 gap-8">
        <UnitEconomicsDeepDiveCard metrics={unitEconomics} />
      </div>

      {/* Investor Recommendations */}
      <div className="grid grid-cols-1 gap-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center gap-3 mb-6">
            <TrendingUp className="h-6 w-6 text-primary" />
            <h2 className="text-xl font-semibold text-gray-900">Recommendations for Investors</h2>
          </div>

          <div className="space-y-6">
            {recommendations.map((section) => (
              <div key={section.title} className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900">{section.title}</h3>
                <ul className="space-y-3">
                  {section.items.map((item, index) => (
                    <li key={index} className="flex items-start text-gray-600">
                      <span className="text-primary mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}