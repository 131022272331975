
import { TrendingUp } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const growthFactors = [
  { name: 'Aging Infrastructure', impact: 15 },
  { name: 'Climate Events', impact: 12 },
  { name: 'Property Development', impact: 8 },
  { name: 'Smart Home Integration', impact: 10 }
];

// Generate compound growth data
const generateGrowthData = () => {
  const data = [];
  let baseValue = 100;
  
  for (let year = 1; year <= 5; year++) {
    const yearData: any = { year: `Year ${year}` };
    growthFactors.forEach(factor => {
      yearData[factor.name] = Math.round(baseValue * (1 + factor.impact / 100) ** year);
    });
    data.push(yearData);
  }
  
  return data;
};

export function GrowthDriversCard() {
  const data = generateGrowthData();

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Market Growth Factors</h2>
      </div>

      <div className="h-80 mb-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            {growthFactors.map((factor, index) => (
              <Line
                key={factor.name}
                type="monotone"
                dataKey={factor.name}
                stroke={['#003366', '#4D9DE0', '#2A9D8F', '#E63946'][index]}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {growthFactors.map((factor, index) => (
          <div key={factor.name} className="bg-gray-50 rounded-lg p-4">
            <h3 className="font-medium text-gray-900 mb-2">{factor.name}</h3>
            <p className="text-2xl font-bold text-primary">
              {factor.impact}%
              <span className="text-sm text-gray-500 ml-1">annual impact</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}