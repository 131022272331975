
import { 
  Users, Code, MessageSquare, Home, Shield, 
  Scale, Headphones, ClipboardList, BookOpen 
} from 'lucide-react';

interface DirectoryItem {
  title: string;
  path: string;
  icon: React.ElementType;
  description: string;
}

export function TeamDirectory() {
  const directories: DirectoryItem[] = [
    {
      title: 'Executive Leadership',
      path: './executive-leadership',
      icon: Users,
      description: 'Strategic vision and company direction'
    },
    {
      title: 'Engineering',
      path: './engineering',
      icon: Code,
      description: 'Platform development and technical infrastructure'
    },
    {
      title: 'AI and Communications',
      path: './ai-communications',
      icon: MessageSquare,
      description: 'AI systems and communication strategies'
    },
    {
      title: 'Property Services',
      path: './property-services',
      icon: Home,
      description: 'Emergency property service management'
    },
    {
      title: 'Security and Quality',
      path: './security-quality',
      icon: Shield,
      description: 'Platform security and service quality'
    },
    {
      title: 'Legal and Compliance',
      path: './legal-compliance',
      icon: Scale,
      description: 'Regulatory compliance and legal oversight'
    },
    {
      title: 'Customer Experience',
      path: './customer-experience',
      icon: Headphones,
      description: 'User experience and customer support'
    },
    {
      title: 'Project Management',
      path: './project-management',
      icon: ClipboardList,
      description: 'Project coordination and delivery'
    },
    {
      title: 'Advisory Board',
      path: './advisory-board',
      icon: BookOpen,
      description: 'Strategic guidance and industry expertise'
    }
  ];

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold text-gray-900 mb-8">Team Directory</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {directories.map((item) => {
          const Icon = item.icon;
          return (
            <div
              key={item.title}
              className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow"
            >
              <div className="flex items-center gap-3 mb-3">
                <Icon className="h-5 w-5 text-blue-600" />
                <h3 className="text-lg font-semibold text-gray-900">{item.title}</h3>
              </div>
              <p className="text-sm text-gray-600">{item.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}