
import { Database, AlertCircle } from 'lucide-react';

export function DataValidation() {
  const dataSources = [
    'Emergency services industry data',
    'Property management metrics',
    'Insurance claim statistics',
    'Technology platform benchmarks',
    'Market research databases',
    'Regulatory compliance requirements'
  ];

  return (
    <div className="mb-16">
      <div className="flex items-center gap-3 mb-6">
        <Database className="h-8 w-8 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Data Sources & Validation</h2>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Our AI system synthesizes insights from:</h3>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {dataSources.map((source, index) => (
              <li key={index} className="text-sm text-gray-600 flex items-start">
                <span className="mr-2 text-blue-600">•</span>
                {source}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex items-start gap-3 p-4 bg-blue-50 rounded-lg">
          <AlertCircle className="h-5 w-5 text-blue-600 mt-0.5" />
          <div>
            <h4 className="text-sm font-semibold text-gray-900 mb-1">Important Note</h4>
            <p className="text-sm text-gray-600">
              All strategic analysis is AI-generated and based on comprehensive data analysis. 
              These insights should be understood as AI-generated recommendations that support 
              our human team's decision-making process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}