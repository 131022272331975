
import { Brain, BarChart, Cpu, Calculator, Database, AlertCircle } from 'lucide-react';
import { AIStrategySection } from './AIStrategySection';
import { TeamDirectory } from './TeamDirectory';
import { DataValidation } from './DataValidation';

export function TeamStructure() {
  return (
    <div className="space-y-8">
      <AIStrategySection />
      <TeamDirectory />
      <DataValidation />
    </div>
  );
}