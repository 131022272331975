import { useState, useEffect } from 'react';
import { 
  DollarSign, TrendingUp, BarChart2, PieChartIcon,
  FileText, Target, BarChart, Brain, Menu, X, Shield,
  ChevronRight
} from 'lucide-react';
import { Overview } from './Overview';
import { CostStructure } from './CostStructure';
import { RevenueModel } from './RevenueModel';
import { MarketAnalysis } from './MarketAnalysis';
import { DetailedProjections } from './DetailedProjections';
import { AICostOptimization } from './AICostOptimization';
import { DetailedCostBreakdown } from './DetailedCostBreakdown';
import { InvestorMetrics } from './InvestorMetrics';
import { RiskMitigation } from './RiskMitigation';
import { UnitEconomicsDeepDiveCard } from './cards/UnitEconomicsDeepDiveCard';
import { RiskManagementCard } from './cards/RiskManagementCard';
import { CashFlowProjectionCard } from './cards/CashFlowProjectionCard';
import { MarketSizeCard } from './cards/MarketSizeCard';
import { ProviderEconomicsCard } from './cards/ProviderEconomicsCard';
import { ProviderProfitabilityCard } from './cards/ProviderProfitabilityCard';
import { useIntl } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';

export function FinancialDashboard() {
  const [activeTab, setActiveTab] = useState('overview');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const tabs = [
    { 
      id: 'overview', 
      name: intl.formatMessage({ id: 'financial.overview', defaultMessage: 'Overview' }), 
      icon: BarChart,
      description: 'Complete financial overview'
    },
    { 
      id: 'revenue', 
      name: intl.formatMessage({ id: 'financial.revenue', defaultMessage: 'Revenue Model' }), 
      icon: DollarSign,
      description: 'Revenue streams and pricing'
    },
    { 
      id: 'costs', 
      name: intl.formatMessage({ id: 'financial.costs', defaultMessage: 'Cost Structure' }), 
      icon: FileText,
      description: 'Cost breakdown and analysis'
    },
    { 
      id: 'market', 
      name: intl.formatMessage({ id: 'financial.market', defaultMessage: 'Market Analysis' }), 
      icon: Target,
      description: 'Market opportunities and trends'
    },
    { 
      id: 'projections', 
      name: intl.formatMessage({ id: 'financial.projections', defaultMessage: 'Financial Projections' }), 
      icon: TrendingUp,
      description: 'Future financial forecasts'
    },
    { 
      id: 'ai-costs', 
      name: intl.formatMessage({ id: 'financial.aiCosts', defaultMessage: 'AI Cost Optimization' }), 
      icon: Brain,
      description: 'AI-driven cost efficiency'
    },
    { 
      id: 'cost-breakdown', 
      name: intl.formatMessage({ id: 'financial.costBreakdown', defaultMessage: 'Detailed Costs' }), 
      icon: PieChartIcon,
      description: 'Detailed cost analysis'
    },
    { 
      id: 'investor-metrics', 
      name: intl.formatMessage({ id: 'financial.investorMetrics', defaultMessage: 'Investor Metrics' }), 
      icon: BarChart2,
      description: 'Key investor KPIs'
    },
    { 
      id: 'risk', 
      name: intl.formatMessage({ id: 'financial.risk', defaultMessage: 'Risk Mitigation' }), 
      icon: Shield,
      description: 'Risk management strategies'
    }
  ];

  const renderContent = () => {
    const content = (() => {
      switch (activeTab) {
        case 'overview':
          return (
            <div className="space-y-8">
              <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">Financial Strategy Dashboard</h1>
                <p className="text-lg text-gray-600">
                  Comprehensive overview of ServiConnect's financial metrics, projections, and risk management
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <MarketSizeCard />
                <ProviderEconomicsCard />
              </div>

              <ProviderProfitabilityCard />

              <UnitEconomicsDeepDiveCard metrics={[
                {
                  metric: 'Customer Acquisition Cost',
                  current: '$500',
                  targetY1: '$400',
                  targetY2: '$350',
                  trend: 'decreasing'
                },
                {
                  metric: 'Lifetime Value',
                  current: '$2000',
                  targetY1: '$2500',
                  targetY2: '$3000',
                  trend: 'increasing'
                }
              ]} />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <CashFlowProjectionCard />
                <RiskManagementCard />
              </div>
            </div>
          );
        case 'revenue':
          return <RevenueModel />;
        case 'costs':
          return <CostStructure />;
        case 'market':
          return <MarketAnalysis />;
        case 'projections':
          return <DetailedProjections />;
        case 'ai-costs':
          return <AICostOptimization />;
        case 'cost-breakdown':
          return <DetailedCostBreakdown />;
        case 'investor-metrics':
          return <InvestorMetrics />;
        case 'risk':
          return <RiskMitigation />;
        default:
          return null;
      }
    })();

    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
        >
          {content}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="relative min-h-[calc(100vh-8rem)]">
      {/* Mobile Menu Button */}
      <div className={`
        md:hidden fixed top-0 left-0 right-0 z-20 p-4 bg-white
        transition-shadow duration-200
        ${isScrolled ? 'shadow-md' : ''}
      `}>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
          aria-expanded={isMobileMenuOpen}
        >
          <span className="sr-only">
            {isMobileMenuOpen ? 'Close menu' : 'Open menu'}
          </span>
          {isMobileMenuOpen ? (
            <X className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <Menu className="block h-6 w-6" aria-hidden="true" />
          )}
        </button>
      </div>

      <div className="flex flex-col md:flex-row">
        {/* Sidebar Navigation */}
        <nav className={`
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0
          fixed md:sticky
          top-[72px] md:top-0
          left-0
          h-[calc(100vh-72px)] md:h-[calc(100vh-8rem)]
          w-64
          bg-white
          border-r border-gray-200
          overflow-y-auto
          transition-transform duration-200
          z-10
          md:block
        `}>
          <div className="w-full p-4 space-y-2">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              const isActive = activeTab === tab.id;
              return (
                <button
                  key={tab.id}
                  onClick={() => {
                    setActiveTab(tab.id);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`
                    w-full flex items-center px-4 py-3 text-sm font-medium rounded-lg
                    transition-all duration-200 ease-in-out
                    group relative
                    ${isActive
                      ? 'bg-primary/10 text-primary'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }
                  `}
                >
                  <Icon className={`
                    h-5 w-5 mr-3 transition-colors
                    ${isActive ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500'}
                  `} />
                  <div className="flex flex-col items-start">
                    <span>{tab.name}</span>
                    <span className="text-xs text-gray-500">{tab.description}</span>
                  </div>
                  {isActive && (
                    <ChevronRight className="absolute right-2 h-5 w-5 text-primary" />
                  )}
                </button>
              );
            })}
          </div>
        </nav>

        {/* Main Content */}
        <main className={`
          flex-1 p-6 md:p-8 lg:p-10
          ${isMobileMenuOpen ? 'blur-sm' : ''}
          transition-all duration-200
          mt-[72px] md:mt-0
          md:ml-64
        `}>
          <div className="max-w-7xl mx-auto space-y-8">
            {renderContent()}
          </div>
        </main>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black/20 z-0 md:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
}