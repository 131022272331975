'use client';

import { DollarSign } from 'lucide-react';

export function CostStructure() {
  return (
    <div className="space-y-8">
      <div className="mb-8">
        <div className="flex items-center gap-3 mb-4">
          <DollarSign className="h-6 w-6 text-primary" />
          <h1 className="text-3xl font-bold text-gray-900">Strategic Investment Allocation</h1>
        </div>
        <p className="text-lg text-gray-600">
          Structured capital deployment focused on building sustainable competitive advantages
        </p>
      </div>

      {/* Technology Infrastructure */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Technology & AI Infrastructure (40%)</h2>
        <ul className="space-y-2 text-gray-600">
          <li>• AI-powered provider matching and quality monitoring</li>
          <li>• Real-time response system development</li>
          <li>• Insurance partner integration platform</li>
          <li>• Scalable cloud infrastructure</li>
        </ul>
      </div>

      {/* Provider Network */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Provider Acquisition & Retention (30%)</h2>
        <ul className="space-y-2 text-gray-600">
          <li>• Premium provider onboarding program</li>
          <li>• Quality assurance systems</li>
          <li>• Provider training and certification</li>
          <li>• Performance-based incentives</li>
        </ul>
      </div>

      {/* Operations */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Operations & Team (20%)</h2>
        <ul className="space-y-2 text-gray-600">
          <li>• Core team expansion</li>
          <li>• Customer support infrastructure</li>
          <li>• Quality control systems</li>
          <li>• Operational automation</li>
        </ul>
      </div>

      {/* Strategic Reserve */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Strategic Contingency (10%)</h2>
        <ul className="space-y-2 text-gray-600">
          <li>• Market opportunity response fund</li>
          <li>• Technology scaling buffer</li>
          <li>• Provider network expansion reserve</li>
          <li>• Emergency response capabilities</li>
        </ul>
      </div>

    </div>
  );
}