import { RouterProvider } from 'react-router-dom';
import { useSession } from '@supabase/auth-helpers-react';
import { router } from './router';
import { Login } from './components';

export default function App() {
  const session = useSession();

  if (!session) {
    return <Login />;
  }

  return <RouterProvider router={router} />;
}