
import { AlertTriangle } from 'lucide-react';

const fallbackSteps = [
  'Detect performance degradation',
  'Switch to cached responses',
  'Activate rule-based system',
  'Notify technical team',
  'Implement manual review if needed'
];

const contingencyMeasures = [
  {
    scenario: 'API Outage',
    primary: 'Switch to cached',
    secondary: 'Rule-based system',
    recovery: '<5 minutes'
  },
  {
    scenario: 'Cost Spike',
    primary: 'Rate limiting',
    secondary: 'Usage quotas',
    recovery: '<15 minutes'
  },
  {
    scenario: 'Performance Drop',
    primary: 'Load balancing',
    secondary: 'Service degradation',
    recovery: '<10 minutes'
  }
];

export function EmergencyFallbackCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <AlertTriangle className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Emergency Fallback Plans</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* System Degradation Response */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">System Degradation Response</h3>
          <div className="space-y-4">
            {fallbackSteps.map((step, index) => (
              <div key={index} className="flex items-center gap-4">
                <div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
                  {index + 1}
                </div>
                <p className="text-gray-600">{step}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Contingency Measures */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Contingency Measures</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Scenario
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Primary Action
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Secondary Action
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Recovery Time
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {contingencyMeasures.map((measure) => (
                  <tr key={measure.scenario}>
                    <td className="px-4 py-3 text-sm text-gray-900">{measure.scenario}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{measure.primary}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{measure.secondary}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{measure.recovery}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}