'use client';

import { ThreeYearForecastCard } from './cards/ThreeYearForecastCard';
import { UnitEconomicsCard } from './cards/UnitEconomicsCard';
import { SensitivityAnalysisCard } from './cards/SensitivityAnalysisCard';
import { BurnRateCard } from './cards/BurnRateCard';
import { SeedInvestmentCard } from './cards/SeedInvestmentCard';
import { MarketEntryCard } from './cards/MarketEntryCard';
import { FinancialRiskMatrixCard } from './cards/FinancialRiskMatrixCard';

interface DetailedProjectionsProps {
  className?: string;
}

export function DetailedProjections({ className = '' }: DetailedProjectionsProps) {
  return (
    <div className={`space-y-8 ${className}`}>
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Seed Investment Financial Projections</h1>
        <p className="text-lg text-gray-600">
          Comprehensive financial analysis and projections for ServiConnect's seed funding round and growth trajectory.
        </p>
      </div>

      {/* Seed Investment Overview */}
      <section className="grid grid-cols-1 gap-8">
        <SeedInvestmentCard />
        <ThreeYearForecastCard />
      </section>

      {/* Financial Analysis */}
      <section className="grid grid-cols-1 gap-8">
        <UnitEconomicsCard />
        <BurnRateCard />
      </section>

      {/* Market and Risk Analysis */}
      <section className="grid grid-cols-1 gap-8">
        <MarketEntryCard />
        <SensitivityAnalysisCard />
        <FinancialRiskMatrixCard />
      </section>
    </div>
  );
}