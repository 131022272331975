
import { TrendingUp } from 'lucide-react';

const thresholds = [
  {
    userBase: '1,000',
    action: 'Enable caching',
    cost: '+$500/mo',
    owner: 'Werner Vogels'
  },
  {
    userBase: '5,000',
    action: 'Add redundancy',
    cost: '+$2,000/mo',
    owner: 'Werner Vogels'
  },
  {
    userBase: '10,000',
    action: 'Geographic distribution',
    cost: '+$5,000/mo',
    owner: 'Werner Vogels'
  }
];

const performanceMetrics = [
  {
    metric: 'Emergency Response Time',
    warning: '<30s',
    critical: '>45s',
    action: '>60s'
  },
  {
    metric: 'Triage Accuracy',
    warning: '>98%',
    critical: '<95%',
    action: '<90%'
  },
  {
    metric: 'System Availability',
    warning: '99.99%',
    critical: '<99.9%',
    action: '<99.5%'
  }
];

export function ScalingThresholdsCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Scaling Thresholds</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Infrastructure Scaling Matrix */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Infrastructure Scaling</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User Base
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action Required
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cost Impact
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Owner
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {thresholds.map((threshold) => (
                  <tr key={threshold.userBase}>
                    <td className="px-4 py-3 text-sm text-gray-900">{threshold.userBase}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{threshold.action}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{threshold.cost}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{threshold.owner}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Performance Thresholds */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Performance Thresholds</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Metric
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Warning
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Critical
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {performanceMetrics.map((metric) => (
                  <tr key={metric.metric}>
                    <td className="px-4 py-3 text-sm text-gray-900">{metric.metric}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{metric.warning}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{metric.critical}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{metric.action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}