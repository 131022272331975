
import { TrendingUp } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const metrics = [
  {
    name: 'ARPU',
    target: 200,
    current: 175
  },
  {
    name: 'CLV',
    target: 2400,
    current: 2100
  },
  {
    name: 'MRR',
    target: 500,
    current: 425
  },
  {
    name: 'Growth',
    target: 20,
    current: 18
  }
];

export function RevenueMetricsCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Revenue Metrics</h2>
      </div>

      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={metrics}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="target" name="Target" fill="#003366" />
            <Bar dataKey="current" name="Current" fill="#4D9DE0" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric) => (
          <div key={metric.name} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-1">{metric.name}</h3>
            <div className="flex items-end gap-2">
              <span className="text-2xl font-bold text-gray-900">
                {metric.current}
                {metric.name === 'Growth' ? '%' : ''}
              </span>
              <span className="text-sm text-gray-500 mb-1">
                / {metric.target}
                {metric.name === 'Growth' ? '%' : ''}
              </span>
            </div>
            <div className="mt-2 h-1 bg-gray-200 rounded-full">
              <div
                className="h-1 bg-primary rounded-full"
                style={{ width: `${(metric.current / metric.target) * 100}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}