'use client';

import { DollarSign } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useState, useEffect } from 'react';
import { BurnRateItem, CHART_COLORS } from './types';

const burnRateData: BurnRateItem[] = [
  { name: 'Development', value: 25650, percentage: 45 },
  { name: 'Marketing', value: 14250, percentage: 25 },
  { name: 'Operations', value: 8550, percentage: 15 },
  { name: 'Legal/Compliance', value: 5700, percentage: 10 },
  { name: 'Reserve', value: 2850, percentage: 5 }
];

const COLORS = [
  CHART_COLORS.primary,
  CHART_COLORS.secondary,
  CHART_COLORS.tertiary,
  CHART_COLORS.quaternary,
  CHART_COLORS.neutral
];

export function BurnRateCard() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 animate-pulse">
        <div className="h-6 bg-gray-200 rounded w-1/4 mb-6"></div>
        <div className="h-80 bg-gray-200 rounded"></div>
      </div>
    );
  }

  const totalBurn = burnRateData.reduce((acc, curr) => acc + curr.value, 0);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <DollarSign className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Monthly Burn Rate</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={burnRateData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {burnRateData.map((_item, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [`$${value.toLocaleString()}`, 'Monthly Burn']}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          {burnRateData.map((item, index) => (
            <div key={item.name} className="flex justify-between items-center">
              <div className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index] }}
                />
                <span className="text-gray-600">{item.name}</span>
              </div>
              <div className="text-right">
                <span className="font-semibold">${item.value.toLocaleString()}</span>
                <span className="text-gray-500 text-sm ml-2">({item.percentage}%)</span>
              </div>
            </div>
          ))}
          <div className="pt-4 border-t">
            <div className="flex justify-between items-center font-semibold">
              <span>Total Monthly Burn</span>
              <span>${totalBurn.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}