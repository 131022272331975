
import { DollarSign, TrendingUp, Target, Award } from 'lucide-react';
import { ServiceFeesCard } from './cards/ServiceFeesCard';
import { SubscriptionTiersCard } from './cards/SubscriptionTiersCard';
import { ProviderRevenueCard } from './cards/ProviderRevenueCard';
import { RevenueMetricsCard } from './cards/RevenueMetricsCard';

export function RevenueModel() {
  return (
    <div className="space-y-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Revenue Model</h1>
        <p className="text-lg text-gray-600">
          Comprehensive breakdown of our revenue streams and pricing strategy.
        </p>
      </div>

      {/* Emergency Service Fees */}
      <ServiceFeesCard />

      {/* Subscription Tiers */}
      <SubscriptionTiersCard />

      {/* Provider Revenue Share */}
      <ProviderRevenueCard />

      {/* Revenue Metrics */}
      <RevenueMetricsCard />
    </div>
  );
}