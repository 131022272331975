'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import enMessages from '../locales/en.json';
import frMessages from '../locales/fr.json';

type SupportedLocale = 'en' | 'fr';

interface LanguageContextType {
  language: SupportedLocale;
  setLanguage: (lang: SupportedLocale) => void;
}

const messages = {
  en: enMessages,
  fr: frMessages,
};

const LanguageContext = createContext<LanguageContextType>({
  language: 'en',
  setLanguage: () => {},
});

export function useLanguage() {
  return useContext(LanguageContext);
}

interface LanguageProviderProps {
  children: React.ReactNode;
}

export function LanguageProvider({ children }: LanguageProviderProps) {
  const [language, setLanguage] = useState<SupportedLocale>(() => {
    const savedLanguage = localStorage.getItem('locale') as SupportedLocale;
    return (savedLanguage && (savedLanguage === 'en' || savedLanguage === 'fr')) ? savedLanguage : 'en';
  });

  const handleSetLanguage = (lang: SupportedLocale) => {
    setLanguage(lang);
    localStorage.setItem('locale', lang);
    // Force reload to ensure all components get the new translations
    window.location.reload();
  };

  const flattenMessages = (nestedMessages: Record<string, any>, prefix = ''): Record<string, string> => {
    return Object.keys(nestedMessages).reduce((acc: Record<string, string>, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        acc[prefixedKey] = value;
      } else {
        Object.assign(acc, flattenMessages(value, prefixedKey));
      }

      return acc;
    }, {});
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: handleSetLanguage }}>
      <IntlProvider
        messages={flattenMessages(messages[language])}
        locale={language}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
