import { useState, useEffect } from 'react';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import { ClipboardList, Plus, Calendar, Target, Users, MoreVertical, Edit2, Trash2, CheckCircle, XCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface Project {
  id: string;
  name: string;
  description: string;
  status: string;
  start_date: string;
  end_date: string;
  budget: number;
}

interface Task {
  id: string;
  project_id: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  assigned_to: string;
  due_date: string;
}

interface Milestone {
  id: string;
  project_id: string;
  title: string;
  description: string;
  target_date: string;
  status: string;
}

interface TeamMember {
  id: string;
  name: string;
  role: string;
  responsibilities: string[];
}

interface SupabaseUser {
  id: string;
  full_name: string;
}

interface ParserError {
  error: true;
}

interface ProjectMemberResponse {
  id: string;
  role: string;
  responsibilities: string[];
  user: SupabaseUser | null;
}

type MemberDataResponse = ProjectMemberResponse | ParserError;

export function ProjectManagement() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const supabase = useSupabaseClient();
  const user = useUser();

  useEffect(() => {
    if (user) {
      loadProjects();
    }
  }, [user]);

  useEffect(() => {
    if (selectedProject) {
      loadProjectData(selectedProject);
    }
  }, [selectedProject]);

  const loadProjects = async () => {
    try {
      const { data: projectsData, error: projectsError } = await supabase
        .rpc('get_user_projects', { p_user_id: user?.id });

      if (projectsError) throw projectsError;
      setProjects(projectsData || []);
      
      if (projectsData?.length > 0 && !selectedProject) {
        setSelectedProject(projectsData[0].id);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadProjectData = async (projectId: string) => {
    try {
      // Load tasks
      const { data: tasksData, error: tasksError } = await supabase
        .rpc('get_project_tasks', { p_project_id: projectId });
      if (tasksError) throw tasksError;
      setTasks(tasksData || []);

      // Load milestones
      const { data: milestonesData, error: milestonesError } = await supabase
        .rpc('get_project_milestones', { p_project_id: projectId });
      if (milestonesError) throw milestonesError;
      setMilestones(milestonesData || []);

      // Load team members
      const { data: membersData, error: membersError } = await supabase
        .from('project_members')
        .select(`
          id,
          role,
          responsibilities,
          user:auth.users (
            id,
            full_name
          )
        `)
        .eq('project_id', projectId);
      
      if (membersError) throw membersError;
      
      // Type guard function to check if the data is a valid ProjectMemberResponse
      const isProjectMemberResponse = (data: MemberDataResponse): data is ProjectMemberResponse => {
        return (
          data !== null &&
          typeof data === 'object' &&
          !('error' in data) &&
          'id' in data &&
          'role' in data &&
          'responsibilities' in data
        );
      };

      // Transform the data to match TeamMember interface
      const transformedMembers: TeamMember[] = (membersData as MemberDataResponse[] || [])
        .filter(isProjectMemberResponse)
        .map(member => ({
          id: member.id,
          name: member.user?.full_name || 'Unknown User',
          role: member.role,
          responsibilities: member.responsibilities || []
        }));
      
      setTeamMembers(transformedMembers);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const createTask = async (taskData: Partial<Task>) => {
    try {
      const { data, error } = await supabase
        .from('project_tasks')
        .insert([{ ...taskData, project_id: selectedProject }])
        .select()
        .single();

      if (error) throw error;
      setTasks([...tasks, data]);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const updateTaskStatus = async (taskId: string, newStatus: string) => {
    try {
      const { error } = await supabase
        .from('project_tasks')
        .update({ status: newStatus })
        .eq('id', taskId);

      if (error) throw error;
      setTasks(tasks.map(task => 
        task.id === taskId ? { ...task, status: newStatus } : task
      ));
    } catch (err: any) {
      setError(err.message);
    }
  };

  const createMilestone = async (milestoneData: Partial<Milestone>) => {
    try {
      const { data, error } = await supabase
        .from('project_milestones')
        .insert([{ ...milestoneData, project_id: selectedProject }])
        .select()
        .single();

      if (error) throw error;
      setMilestones([...milestones, data]);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-3">
          <ClipboardList className="h-8 w-8 text-primary" />
          <h2 className="text-2xl font-bold text-gray-900">Project Management</h2>
        </div>
        <button
          onClick={() => {/* TODO: Open new project modal */}}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Project
        </button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Project List */}
          <div className="lg:col-span-1 space-y-4">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Projects</h3>
              <div className="space-y-2">
                {projects.map((project) => (
                  <button
                    key={project.id}
                    onClick={() => setSelectedProject(project.id)}
                    className={`w-full text-left px-4 py-3 rounded-lg transition-colors ${
                      selectedProject === project.id
                        ? 'bg-primary/10 text-primary'
                        : 'hover:bg-gray-50 text-gray-700'
                    }`}
                  >
                    <div className="font-medium">{project.name}</div>
                    <div className="text-sm text-gray-500 truncate">
                      {project.description}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Tasks and Milestones */}
          <div className="lg:col-span-2 space-y-6">
            {selectedProject && (
              <>
                {/* Tasks Section */}
                <div className="bg-white rounded-xl shadow-sm p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold text-gray-900">Tasks</h3>
                    <button
                      onClick={() => {/* TODO: Open new task modal */}}
                      className="text-primary hover:text-primary/80"
                    >
                      <Plus className="h-5 w-5" />
                    </button>
                  </div>
                  <div className="space-y-3">
                    {tasks.map((task) => (
                      <div
                        key={task.id}
                        className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                      >
                        <div className="flex items-center space-x-3">
                          <button
                            onClick={() => updateTaskStatus(task.id, task.status === 'completed' ? 'pending' : 'completed')}
                            className={`flex-shrink-0 ${
                              task.status === 'completed' ? 'text-green-500' : 'text-gray-400'
                            }`}
                          >
                            {task.status === 'completed' ? (
                              <CheckCircle className="h-5 w-5" />
                            ) : (
                              <div className="h-5 w-5 border-2 border-current rounded-full" />
                            )}
                          </button>
                          <span className={task.status === 'completed' ? 'line-through text-gray-500' : ''}>
                            {task.title}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <span className="text-sm text-gray-500">
                            {new Date(task.due_date).toLocaleDateString()}
                          </span>
                          <button className="text-gray-400 hover:text-gray-600">
                            <MoreVertical className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Milestones Section */}
                <div className="bg-white rounded-xl shadow-sm p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold text-gray-900">Milestones</h3>
                    <button
                      onClick={() => {/* TODO: Open new milestone modal */}}
                      className="text-primary hover:text-primary/80"
                    >
                      <Plus className="h-5 w-5" />
                    </button>
                  </div>
                  <div className="space-y-4">
                    {milestones.map((milestone) => (
                      <div
                        key={milestone.id}
                        className="border-l-4 border-primary pl-4 py-2"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="font-medium text-gray-900">{milestone.title}</h4>
                            <p className="text-sm text-gray-500">{milestone.description}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="text-sm text-gray-500">
                              {new Date(milestone.target_date).toLocaleDateString()}
                            </span>
                            <div className="flex space-x-1">
                              <button className="p-1 text-gray-400 hover:text-gray-600">
                                <Edit2 className="h-4 w-4" />
                              </button>
                              <button className="p-1 text-gray-400 hover:text-red-600">
                                <Trash2 className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Team Members Section */}
                <div className="bg-white rounded-xl shadow-sm p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold text-gray-900">Team Members</h3>
                    <button
                      onClick={() => {/* TODO: Open invite member modal */}}
                      className="text-primary hover:text-primary/80"
                    >
                      <Users className="h-5 w-5" />
                    </button>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {teamMembers.map((member) => (
                      <div
                        key={member.id}
                        className="p-4 bg-gray-50 rounded-lg"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="font-medium text-gray-900">{member.name}</h4>
                            <p className="text-sm text-primary">{member.role}</p>
                          </div>
                          <button className="text-gray-400 hover:text-gray-600">
                            <MoreVertical className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}