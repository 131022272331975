import { Code } from 'lucide-react';
import { TeamSection } from './TeamSection';
import { TeamMember } from './types';

export function EngineeringTeam() {
  const engineeringTeam: TeamMember[] = [
    {
      name: 'Sebastián Ramírez - Persona AI Advisor',
      role: 'Lead Backend Architect',
      responsibilities: [
        'FastAPI implementation',
        'API architecture',
        'Performance optimization'
      ],
      image: '/team/engineering/sebastian-ramirez.jpg'
    },
    {
      name: 'Kent C. Dodds - Persona AI Advisor',
      role: 'Frontend Lead',
      responsibilities: [
        'React architecture',
        'Component design',
        'Testing strategy'
      ],
      image: '/team/engineering/kent-dodds.jpg'
    },
    {
      name: 'Dan Abramov - Persona AI Advisor',
      role: 'React Architecture Lead',
      responsibilities: [
        'React optimization',
        'State management',
        'Performance tuning'
      ],
      image: '/team/engineering/dan-abramov.jpg'
    },
    {
      name: 'Rich Harris - Persona AI Advisor',
      role: 'Frontend Architecture',
      responsibilities: [
        'Build optimization',
        'Frontend tooling',
        'Performance metrics'
      ],
      image: '/team/engineering/rich-harris.jpg'
    },
    {
      name: 'Evan You - Persona AI Advisor',
      role: 'Frontend Innovation',
      responsibilities: [
        'UI architecture',
        'Performance strategy',
        'Developer experience'
      ],
      image: '/team/engineering/evan-you.jpg'
    },
    {
      name: 'Sarah Drasner - Persona AI Advisor',
      role: 'Developer Experience',
      responsibilities: [
        'Developer tooling',
        'Documentation',
        'Team productivity'
      ],
      image: '/team/engineering/sarah-drasner.jpg'
    }
  ];

  return <TeamSection title="Engineering" icon={Code} members={engineeringTeam} />;
}