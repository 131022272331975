import { Shield } from 'lucide-react';
import { TeamSection } from './TeamSection';
import { TeamMember } from './types';

export function SecurityTeam() {
  const securityTeam: TeamMember[] = [
    {
      name: 'Justin Seitz - Persona AI Advisor',
      role: 'Python Security Lead',
      responsibilities: [
        'Code security',
        'Security testing',
        'Vulnerability assessment'
      ],
      image: '/team/security/justin-seitz.jpg'
    },
    {
      name: 'Marit van Dijk - Persona AI Advisor',
      role: 'Security Testing Lead',
      responsibilities: [
        'Security verification',
        'Test automation',
        'Quality assurance'
      ],
      image: '/team/security/marit-vandijk.jpg'
    },
    {
      name: 'Angie Jones - Persona AI Advisor',
      role: 'QA Lead',
      responsibilities: [
        'Testing strategy',
        'Quality standards',
        'Process improvement'
      ],
      image: '/team/security/angie-jones.jpg'
    },
    {
      name: 'Brian Okken - Persona AI Advisor',
      role: 'Python Testing Lead',
      responsibilities: [
        'Test automation',
        'Python testing',
        'Quality metrics'
      ],
      image: '/team/security/brian-okken.jpg'
    },
    {
      name: 'Harry Percival - Persona AI Advisor',
      role: 'TDD Implementation Lead',
      responsibilities: [
        'Test driven development',
        'Quality assurance',
        'Process automation'
      ],
      image: '/team/security/harry-percival.jpg'
    }
  ];

  return <TeamSection title="Security & Testing" icon={Shield} members={securityTeam} />;
}