import { useEffect, useState } from 'react';
import { useSession } from '@supabase/auth-helpers-react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useStore } from '../store';
import { AdminLayout } from './admin/AdminLayout';
import { toast } from 'react-hot-toast';
import { supabase, User, Invitation, Profile } from '../lib/supabase';
import { AdminDashboard } from './admin/AdminDashboard';
import { UserManagement } from './admin/UserManagement';
import { AdminSettings } from './admin/AdminSettings';

export function Admin() {
  const [pendingInvitations, setPendingInvitations] = useState<Invitation[]>([]);
  const [registeredUsers, setRegisteredUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    async function checkAdminAccess() {
      if (!session?.user?.id) {
        navigate('/login');
        return;
      }

      try {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', session.user.id)
          .single();

        if (profileError || !profile || profile.role !== 'admin') {
          toast.error('Unauthorized: Admin access required');
          navigate('/dashboard');
          return;
        }

        // If we get here, the user is an admin
        await Promise.all([loadInvitations(), loadRegisteredUsers()]);
      } catch (error) {
        console.error('Error checking admin access:', error);
        toast.error('Error checking admin access');
        navigate('/dashboard');
      } finally {
        setIsLoading(false);
      }
    }

    checkAdminAccess();
  }, [session, navigate]);

  const loadInvitations = async () => {
    try {
      const { data, error } = await supabase
        .from('invitations')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPendingInvitations(data || []);
    } catch (error) {
      console.error('Error loading invitations:', error);
      setError('Failed to load invitations');
    }
  };

  const loadRegisteredUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRegisteredUsers(data || []);
    } catch (error) {
      console.error('Error loading users:', error);
      setError('Failed to load users');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Routes>
          <Route index element={<AdminDashboard users={registeredUsers} invitations={pendingInvitations} />} />
          <Route 
            path="users" 
            element={
              <UserManagement 
                users={registeredUsers} 
                onRefresh={loadRegisteredUsers} 
              />
            } 
          />
          <Route path="settings" element={<AdminSettings />} />
        </Routes>
      </div>
    </div>
  );
}
