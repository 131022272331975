'use client';

import { BarChart2 } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState, useEffect } from 'react';
import { ForecastDataPoint, ChartConfig } from '../../../types/financial';
import { formatCurrency, calculateNetPosition, getChartColors } from '../../../utils/financialCalculations';

const chartConfig: ChartConfig[] = [
  { dataKey: 'development', name: 'Development', fill: getChartColors.development },
  { dataKey: 'marketing', name: 'Marketing', fill: getChartColors.marketing },
  { dataKey: 'revenue', name: 'Revenue', fill: getChartColors.revenue },
  { dataKey: 'netPosition', name: 'Net Position', fill: getChartColors.netPosition }
];

const initialData: ForecastDataPoint[] = [
  {
    quarter: 'Q1 2024',
    development: -15000,
    marketing: -2000,
    revenue: 0,
    netPosition: -17000
  },
  {
    quarter: 'Q2 2024',
    development: -12000,
    marketing: -3000,
    revenue: 5000,
    netPosition: -10000
  },
  {
    quarter: 'Q3 2024',
    development: -10000,
    marketing: -5000,
    revenue: 15000,
    netPosition: 0
  },
  {
    quarter: 'Q4 2024',
    development: -8000,
    marketing: -7000,
    revenue: 25000,
    netPosition: 10000
  }
].map(item => ({
  ...item,
  netPosition: calculateNetPosition(item)
}));

export function ThreeYearForecastCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ForecastDataPoint[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        setData(initialData);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load forecast data');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="animate-pulse space-y-4" data-testid="loading-skeleton">
          <div className="h-6 bg-gray-200 rounded w-1/4"></div>
          <div className="h-80 bg-gray-200 rounded"></div>
          <div className="h-40 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-red-600">
          <h2 className="text-xl font-semibold mb-2">Error Loading Forecast</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-gray-600">
          <h2 className="text-xl font-semibold mb-2">No Forecast Data Available</h2>
          <p>Please check back later for updated forecasts.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <BarChart2 className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Three-Year Financial Forecast</h2>
      </div>

      <div className="h-80 mb-6">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="quarter" />
            <YAxis tickFormatter={(value) => formatCurrency(value)} />
            <Tooltip 
              formatter={(value: number) => formatCurrency(value)}
              labelFormatter={(label) => `Quarter: ${label}`}
            />
            <Legend />
            {chartConfig.map((config) => (
              <Bar
                key={config.dataKey}
                dataKey={config.dataKey}
                name={config.name}
                fill={config.fill}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Quarter
              </th>
              {chartConfig.map((config) => (
                <th
                  key={config.dataKey}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {config.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((quarter) => (
              <tr key={quarter.quarter}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {quarter.quarter}
                </td>
                {chartConfig.map((config) => (
                  <td
                    key={config.dataKey}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                  >
                    {formatCurrency(Number(quarter[config.dataKey]))}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}