'use client';

import { TrendingUp } from 'lucide-react';

interface MetricData {
  label: string;
  value: string;
  trend?: 'up' | 'down' | 'stable';
}

interface GrowthMetricsCardProps {
  metrics: MetricData[];
}

export function GrowthMetricsCard({ metrics }: GrowthMetricsCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Growth Metrics</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric) => (
          <div key={metric.label} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-1">{metric.label}</h3>
            <p className="text-lg font-semibold text-gray-900">{metric.value}</p>
            {metric.trend && (
              <div className={`mt-2 text-sm ${
                metric.trend === 'up' ? 'text-green-600' :
                metric.trend === 'down' ? 'text-red-600' :
                'text-gray-600'
              }`}>
                {metric.trend === 'up' ? '↑ Increasing' :
                 metric.trend === 'down' ? '↓ Decreasing' :
                 '→ Stable'}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}