
import { Target, TrendingUp, BarChart2 } from 'lucide-react';
import { MarketSizeCard } from './cards/MarketSizeCard';
import { GrowthDriversCard } from './cards/GrowthDriversCard';
import { CompetitiveAnalysisCard } from './cards/CompetitiveAnalysisCard';
import { MarketEntryCard } from './cards/MarketEntryCard';

export function MarketAnalysis() {
  return (
    <div className="space-y-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Market Analysis</h1>
        <p className="text-lg text-gray-600">
          Comprehensive analysis of market opportunities and competitive landscape.
        </p>
      </div>

      {/* Market Size */}
      <MarketSizeCard />

      {/* Growth Drivers */}
      <GrowthDriversCard />

      {/* Competitive Analysis */}
      <CompetitiveAnalysisCard />

      {/* Market Entry */}
      <MarketEntryCard />
    </div>
  );
}