
import { Users } from 'lucide-react';

const commissionStructure = [
  {
    tier: 'New Provider',
    baseCommission: 25,
    volumeBonus: 0,
    netShare: 25
  },
  {
    tier: 'Silver (>50 jobs)',
    baseCommission: 22,
    volumeBonus: 2,
    netShare: 24
  },
  {
    tier: 'Gold (>100 jobs)',
    baseCommission: 20,
    volumeBonus: 5,
    netShare: 25
  },
  {
    tier: 'Platinum (>200 jobs)',
    baseCommission: 18,
    volumeBonus: 8,
    netShare: 26
  }
];

const incentives = [
  {
    metric: 'Response Time <15min',
    reward: '-2% commission',
    cost: 50,
    roi: 3
  },
  {
    metric: 'Rating >4.8',
    reward: '-1% commission',
    cost: 30,
    roi: 2.5
  },
  {
    metric: 'Volume >10/month',
    reward: 'Priority Matching',
    cost: 20,
    roi: 4
  }
];

export function ProviderRevenueCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Users className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Provider Revenue Share</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Commission Structure */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Commission Structure</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Provider Tier
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Base Commission
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Volume Bonus
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Net Share
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {commissionStructure.map((tier) => (
                  <tr key={tier.tier}>
                    <td className="px-4 py-3 text-sm text-gray-900">{tier.tier}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{tier.baseCommission}%</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{tier.volumeBonus}%</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{tier.netShare}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Provider Incentives */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Provider Incentives</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Metric
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Reward
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cost/Month
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ROI
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {incentives.map((incentive) => (
                  <tr key={incentive.metric}>
                    <td className="px-4 py-3 text-sm text-gray-900">{incentive.metric}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{incentive.reward}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">${incentive.cost}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{incentive.roi}x</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}