import { useState } from 'react';
import { 
  Users, Code, MessageSquare, Home, Shield, Scale, 
  Headphones, ClipboardList, BookOpen, Brain,
  Menu, X, ChevronRight
} from 'lucide-react';
import { ExecutiveTeam } from './team/ExecutiveTeam';
import { AdvisoryBoard } from './team/AdvisoryBoard';
import { EngineeringTeam } from './team/EngineeringTeam';
import { TeamStructure } from './team/TeamStructure';
import { LegalTeam } from './team/LegalTeam';
import { SecurityTeam } from './team/SecurityTeam';
import { AITeam } from './team/AITeam';
import { CustomerExperience } from './team/CustomerExperience';
import { ProjectManagement } from './team/ProjectManagement';
import { PropertyServices } from './team/PropertyServices';
import { motion } from 'framer-motion';

interface TeamTab {
  id: string;
  label: string;
  icon: React.ElementType;
  description?: string;
}

export function Team() {
  const [activeTab, setActiveTab] = useState('structure');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const tabs: TeamTab[] = [
    { 
      id: 'structure', 
      label: 'Team Structure', 
      icon: Home,
      description: 'Overview of our organizational structure'
    },
    { 
      id: 'executive', 
      label: 'Executive Team', 
      icon: Users,
      description: 'Meet our leadership team'
    },
    { 
      id: 'advisory', 
      label: 'Advisory Board', 
      icon: BookOpen,
      description: 'Our expert advisors'
    },
    { 
      id: 'engineering', 
      label: 'Engineering', 
      icon: Code,
      description: 'Technical development team'
    },
    { 
      id: 'ai', 
      label: 'AI & Communications', 
      icon: Brain,
      description: 'AI and communications specialists'
    },
    { 
      id: 'security', 
      label: 'Security & Quality', 
      icon: Shield,
      description: 'Security and quality assurance'
    },
    { 
      id: 'customer', 
      label: 'Customer Experience', 
      icon: Headphones,
      description: 'Customer support and success'
    },
    { 
      id: 'property', 
      label: 'Property Services', 
      icon: Home,
      description: 'Property management team'
    },
    { 
      id: 'project', 
      label: 'Project Management', 
      icon: ClipboardList,
      description: 'Project coordination and delivery'
    },
    { 
      id: 'legal', 
      label: 'Legal & Compliance', 
      icon: Scale,
      description: 'Legal and compliance team'
    }
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'structure':
        return <TeamStructure />;
      case 'executive':
        return <ExecutiveTeam />;
      case 'advisory':
        return <AdvisoryBoard />;
      case 'engineering':
        return <EngineeringTeam />;
      case 'ai':
        return <AITeam />;
      case 'security':
        return <SecurityTeam />;
      case 'customer':
        return <CustomerExperience />;
      case 'property':
        return <PropertyServices />;
      case 'project':
        return <ProjectManagement />;
      case 'legal':
        return <LegalTeam />;
      default:
        return <TeamStructure />;
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-0 left-0 right-0 h-[72px] bg-white border-b border-gray-200 px-4 flex items-center justify-between z-20">
        <h1 className="text-xl font-semibold text-gray-900">Team</h1>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 rounded-md text-gray-500 hover:text-gray-600 hover:bg-gray-100"
        >
          {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </button>
      </div>

      <div className="lg:flex">
        {/* Navigation Sidebar */}
        <nav className={`
          fixed lg:sticky top-0 z-10
          h-full w-72 lg:h-[100vh]
          bg-white border-r border-gray-200
          transform lg:transform-none
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
          transition-transform duration-200 ease-in-out
          overflow-y-auto
          pt-[72px] lg:pt-8
        `}>
          <div className="px-4">
            <div className="space-y-1">
              {tabs.map((tab) => {
                const Icon = tab.icon;
                const isActive = activeTab === tab.id;
                
                return (
                  <button
                    key={tab.id}
                    onClick={() => {
                      setActiveTab(tab.id);
                      setIsMobileMenuOpen(false);
                    }}
                    className={`
                      w-full flex items-start px-3 py-3 text-sm font-medium rounded-lg
                      transition-all duration-200 ease-in-out
                      group relative
                      ${isActive
                        ? 'bg-primary/10 text-primary'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      }
                    `}
                  >
                    <Icon className={`
                      h-5 w-5 mr-3 mt-0.5 flex-shrink-0 transition-colors
                      ${isActive ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500'}
                    `} />
                    <div className="flex flex-col items-start text-left min-w-0">
                      <span className="truncate w-full">{tab.label}</span>
                      {tab.description && (
                        <span className={`
                          text-xs mt-0.5 w-full truncate
                          ${isActive ? 'text-primary/70' : 'text-gray-500'}
                        `}>
                          {tab.description}
                        </span>
                      )}
                    </div>
                    {isActive && (
                      <ChevronRight className="absolute right-2 top-1/2 -translate-y-1/2 h-5 w-5 text-primary" />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className={`
          flex-1 px-4 py-8 lg:px-8
          ${isMobileMenuOpen ? 'blur-sm' : ''}
          transition-all duration-200
          mt-[72px] lg:mt-0
        `}>
          <div className="max-w-7xl mx-auto">
            <div className="mb-8">
              <motion.h1 
                className="text-3xl font-bold text-gray-900 mb-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                Our Team
              </motion.h1>
              <motion.p 
                className="text-lg text-gray-600"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                Meet the dedicated professionals behind ServiConnect.
              </motion.p>
            </div>

            {renderContent()}
          </div>
        </main>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black/20 z-0 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
}