import { useState } from 'react';
import { 
  FileText, Target, DollarSign, TrendingUp, Shield, 
  Users, BarChart2, Map, Building 
} from 'lucide-react';

interface Section {
  id: string;
  name: string;
  icon: React.ElementType;
}

export function BusinessPlan() {
  const [activeSection, setActiveSection] = useState('executive');

  const sections: Section[] = [
    { id: 'executive', name: 'Executive Summary', icon: FileText },
    { id: 'market', name: 'Market Analysis', icon: Target },
    { id: 'financial', name: 'Financial Strategy', icon: DollarSign },
    { id: 'growth', name: 'Growth Plan', icon: TrendingUp },
    { id: 'operations', name: 'Operations', icon: Building },
    { id: 'team', name: 'Team Structure', icon: Users },
    { id: 'metrics', name: 'Key Metrics', icon: BarChart2 },
    { id: 'risk', name: 'Risk Management', icon: Shield },
    { id: 'expansion', name: 'Expansion Strategy', icon: Map }
  ];

  const renderContent = () => {
    switch (activeSection) {
      case 'executive':
        return (
          <div className="space-y-8">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Project Vision</h2>
              <p className="text-gray-600 mb-6">
                ServiConnect aims to revolutionize emergency home services through AI-powered technology,
                connecting users with reliable service providers in under 30 minutes—eight times faster
                than the industry standard—while reducing costs by up to 80%.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">Market Validation</h3>
                  <ul className="space-y-2">
                    {[
                      'Three major insurance companies signed LOIs',
                      '75 service providers committed',
                      'Two property management firms piloting',
                      'Quebec regulatory compliance secured'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start text-gray-600">
                        <span className="text-primary mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">Investment Opportunity</h3>
                  <ul className="space-y-2">
                    {[
                      '$2.5M Seed Round at $10M valuation',
                      'Break-even at 8,500 monthly transactions',
                      '2,600 active customers target',
                      '$2.7M annual recurring revenue goal'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start text-gray-600">
                        <span className="text-primary mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Core Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[
                  {
                    title: 'AI-Powered Triage',
                    desc: 'Smart emergency classification and provider matching'
                  },
                  {
                    title: 'Real-time Tracking',
                    desc: 'Live provider location and ETA updates'
                  },
                  {
                    title: 'Instant Payments',
                    desc: 'Secure, automated payment processing'
                  },
                  {
                    title: 'Quality Assurance',
                    desc: 'Provider ratings and service guarantees'
                  },
                  {
                    title: 'Insurance Integration',
                    desc: 'Direct insurance company connections'
                  },
                  {
                    title: 'Analytics Dashboard',
                    desc: 'Comprehensive service metrics and insights'
                  }
                ].map((feature) => (
                  <div key={feature.title} className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-semibold text-gray-900 mb-2">{feature.title}</h3>
                    <p className="text-sm text-gray-600">{feature.desc}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Competitive Advantages</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">Market Position</h3>
                  <ul className="space-y-2">
                    {[
                      'AI-powered response optimization',
                      'Comprehensive provider network',
                      'Insurance company partnerships',
                      'Regulatory compliance framework'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start text-gray-600">
                        <span className="text-primary mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">Technology Edge</h3>
                  <ul className="space-y-2">
                    {[
                      'Advanced AI triage system',
                      'Real-time tracking platform',
                      'Automated quality control',
                      'Data-driven optimization'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start text-gray-600">
                        <span className="text-primary mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      // Add cases for other sections...
      default:
        return null;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Business Plan</h1>
        <p className="text-lg text-gray-600">
          Comprehensive overview of ServiConnect's business strategy and execution plan.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Vertical Navigation */}
        <nav className="lg:w-64 flex-shrink-0">
          <div className="space-y-1">
            {sections.map((section) => {
              const Icon = section.icon;
              return (
                <button
                  key={section.id}
                  onClick={() => setActiveSection(section.id)}
                  className={`
                    w-full flex items-center px-4 py-3 text-sm font-medium rounded-lg
                    ${activeSection === section.id
                      ? 'bg-primary/10 text-primary'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }
                  `}
                >
                  <Icon className={`
                    h-5 w-5 mr-3
                    ${activeSection === section.id ? 'text-primary' : 'text-gray-400'}
                  `} />
                  {section.name}
                </button>
              );
            })}
          </div>
        </nav>

        {/* Main Content */}
        <div className="flex-1 min-w-0">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}