'use client';

import { TrendingUp } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState, useEffect } from 'react';
import { SensitivityDataPoint, CHART_COLORS } from './types';

const generateSensitivityData = (): SensitivityDataPoint[] => {
  const data: SensitivityDataPoint[] = [];
  const baseRevenue = 1000000;

  for (let change = -30; change <= 30; change += 10) {
    data.push({
      change: `${change}%`,
      providerAcquisition: baseRevenue * (1 + (change * 1.2) / 100),
      commission: baseRevenue * (1 + (change * 0.8) / 100),
      retention: baseRevenue * (1 + (change * 1.5) / 100),
      frequency: baseRevenue * (1 + (change * 1.0) / 100),
    });
  }

  return data;
};

export function SensitivityAnalysisCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<SensitivityDataPoint[]>([]);

  // Simulate data loading
  useEffect(() => {
    try {
      const generatedData = generateSensitivityData();
      setData(generatedData);
      setIsLoading(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate sensitivity data');
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 animate-pulse">
        <div className="h-6 bg-gray-200 rounded w-1/4 mb-6"></div>
        <div className="h-80 bg-gray-200 rounded"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-red-600">
          <h2 className="text-xl font-semibold mb-2">Error Loading Sensitivity Analysis</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Sensitivity Analysis</h2>
      </div>

      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="change" />
            <YAxis tickFormatter={(value: number) => `$${(value / 1000000).toFixed(1)}M`} />
            <Tooltip 
              formatter={(value: number) => [`$${(value / 1000000).toFixed(2)}M`, '']}
              labelFormatter={(label: string) => `Change: ${label}`}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="providerAcquisition"
              name="Provider Acquisition Rate"
              stroke={CHART_COLORS.primary}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="commission"
              name="Commission Rate"
              stroke={CHART_COLORS.secondary}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="retention"
              name="Customer Retention"
              stroke={CHART_COLORS.tertiary}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="frequency"
              name="Service Frequency"
              stroke={CHART_COLORS.quaternary}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}