import { Brain } from 'lucide-react';
import type { SystemSafeguard, RecoveryObjective } from '../../../types/risk';

const systemSafeguards: SystemSafeguard[] = [
  {
    control: 'Model Validation',
    description: 'Regular validation of AI model performance and bias checks',
    status: 'Active'
  },
  {
    control: 'Data Quality',
    description: 'Continuous monitoring of training data quality and relevance',
    status: 'Active'
  },
  {
    control: 'Ethics Review',
    description: 'Periodic review of AI decisions for ethical compliance',
    status: 'Scheduled'
  },
  {
    control: 'Fallback Systems',
    description: 'Manual override capabilities for critical decisions',
    status: 'Active'
  }
];

const recoveryObjectives: RecoveryObjective[] = [
  {
    objective: 'Model Retraining',
    timeframe: '48 hours',
    priority: 'High'
  },
  {
    objective: 'Data Backup',
    timeframe: '24 hours',
    priority: 'Critical'
  },
  {
    objective: 'System Restore',
    timeframe: '12 hours',
    priority: 'Critical'
  },
  {
    objective: 'Performance Review',
    timeframe: '1 week',
    priority: 'Medium'
  }
];

export function AIRiskManagementCard() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center mb-4">
        <Brain className="w-6 h-6 text-blue-500 mr-2" />
        <h2 className="text-xl font-semibold text-gray-900">AI Risk Management</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">System Safeguards</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Control
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {systemSafeguards.map((safeguard, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{safeguard.control}</td>
                    <td className="px-4 py-3 text-sm text-gray-500">{safeguard.description}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          safeguard.status === 'Active'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {safeguard.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">Recovery Time Objectives</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Objective
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Timeframe
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Priority
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {recoveryObjectives.map((objective, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{objective.objective}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{objective.timeframe}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          objective.priority === 'Critical'
                            ? 'bg-red-100 text-red-800'
                            : objective.priority === 'High'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-blue-100 text-blue-800'
                        }`}
                      >
                        {objective.priority}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}