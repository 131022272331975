
import { Users } from 'lucide-react';

export function ExecutiveTeam() {
  const executives = [
    {
      name: 'Satya Nadella - Persona AI Advisor',
      role: 'Chief Executive Officer (CEO)',
      responsibilities: [
        'Strategic leadership and vision',
        'Executive team management',
        'Key partnership development'
      ]
    },
    {
      name: 'Ruth Porat - Persona AI Advisor',
      role: 'Chief Financial Officer (CFO)',
      responsibilities: [
        'Financial strategy and planning',
        'Investment oversight',
        'Risk management',
        'Emergency service economics',
        'Regional market analysis',
        'Technology investment planning'
      ]
    },
    {
      name: 'Werner Vogels - Persona AI Advisor',
      role: 'Chief Technology Officer (CTO)',
      responsibilities: [
        'Technical architecture direction',
        'Innovation strategy',
        'Technology stack decisions'
      ]
    },
    {
      name: 'Sheryl Sandberg - Persona AI Advisor',
      role: 'Chief Operating Officer (COO)',
      responsibilities: [
        'Operational excellence',
        'Service provider network',
        'Growth strategy'
      ]
    },
    {
      name: 'DJ Patil - Persona AI Advisor',
      role: 'Chief Data Officer (CDO)',
      responsibilities: [
        'Data strategy',
        'Analytics oversight',
        'AI governance'
      ]
    }
  ];

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-3 mb-8">
        <Users className="h-8 w-8 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Executive Leadership Team</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {executives.map((executive) => (
          <div
            key={executive.name}
            className="bg-white rounded-lg shadow-sm p-6"
          >
            <h3 className="text-lg font-semibold text-gray-900">{executive.name}</h3>
            <p className="text-sm text-blue-600 mb-3">{executive.role}</p>
            <ul className="space-y-1">
              {executive.responsibilities.map((resp, index) => (
                <li key={index} className="text-sm text-gray-600 flex items-start">
                  <span className="mr-2 text-blue-600">•</span>
                  {resp}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}