import { Task, Milestone } from '../types';

export const initialMilestones: Milestone[] = [
  {
    id: 'milestone-1',
    title: 'Project Setup & Infrastructure',
    description: 'Initial project setup and infrastructure configuration',
    dueDate: '2024-01-15',
    status: 'in_progress',
    tasks: [],
  },
  {
    id: 'milestone-2',
    title: 'Core Backend Architecture',
    description: 'Establish the foundational backend architecture',
    dueDate: '2024-02-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-3',
    title: 'Emergency Triage System',
    description: 'Build the core triage question system and decision tree',
    dueDate: '2024-03-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-4',
    title: 'Frontend Development',
    description: 'Develop user and provider interfaces',
    dueDate: '2024-04-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-5',
    title: 'Payment Integration',
    description: 'Integrate payment processing and pricing systems',
    dueDate: '2024-05-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-6',
    title: 'Rating & Review System',
    description: 'Implement comprehensive rating and review functionality',
    dueDate: '2024-06-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-7',
    title: 'Testing',
    description: 'Comprehensive testing suite implementation',
    dueDate: '2024-07-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-8',
    title: 'Performance Optimization',
    description: 'System optimization and monitoring setup',
    dueDate: '2024-08-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-9',
    title: 'Security & Compliance',
    description: 'Ensure security and regulatory compliance',
    dueDate: '2024-09-15',
    status: 'planned',
    tasks: [],
  },
  {
    id: 'milestone-10',
    title: 'Launch Readiness',
    description: 'Final preparation for system launch',
    dueDate: '2024-10-15',
    status: 'planned',
    tasks: [],
  },
];

export const initialTasks: Task[] = [
  {
    id: 'task-1',
    title: 'Set up development environment',
    description: 'Configure development tools and environment',
    status: 'done',
    priority: 'high',
    milestoneId: 'Project Setup & Infrastructure',
    completed: true,
    progress: 100,
  },
  {
    id: 'task-2-1',
    title: 'Set up FastAPI application structure',
    completed: false,
    milestoneId: 'Core Backend Architecture',
    description: 'Initialize FastAPI project with basic structure',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-2-2',
    title: 'Implement error handling middleware',
    completed: false,
    milestoneId: 'Core Backend Architecture',
    description: 'Create comprehensive error handling system',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-2-3',
    title: 'Set up AI model infrastructure',
    completed: false,
    milestoneId: 'Core Backend Architecture',
    description: 'Prepare infrastructure for AI model deployment',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-3-1',
    title: 'Implement triage question system',
    completed: false,
    milestoneId: 'Emergency Triage System',
    description: 'Create dynamic triage questionnaire system',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-3-2',
    title: 'Create decision tree logic',
    completed: false,
    milestoneId: 'Emergency Triage System',
    description: 'Implement decision tree for emergency classification',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-3-3',
    title: 'Build provider matching algorithm',
    completed: false,
    milestoneId: 'Emergency Triage System',
    description: 'Develop algorithm for matching patients with providers',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-4-1',
    title: 'Create responsive layout system',
    completed: false,
    milestoneId: 'Frontend Development',
    description: 'Implement responsive design framework',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-4-2',
    title: 'Build provider dashboard',
    completed: false,
    milestoneId: 'Frontend Development',
    description: 'Create comprehensive provider interface',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-4-3',
    title: 'Implement real-time status updates',
    completed: false,
    milestoneId: 'Frontend Development',
    description: 'Add real-time notification system',
    priority: 'medium',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-5-1',
    title: 'Integrate Stripe payment processing',
    completed: false,
    milestoneId: 'Payment Integration',
    description: 'Set up Stripe integration for payments',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-5-2',
    title: 'Implement automated payouts',
    completed: false,
    milestoneId: 'Payment Integration',
    description: 'Create automated provider payout system',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-5-3',
    title: 'Build dynamic pricing system',
    completed: false,
    milestoneId: 'Payment Integration',
    description: 'Implement flexible pricing algorithm',
    priority: 'medium',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-6-1',
    title: 'Create rating collection system',
    completed: false,
    milestoneId: 'Rating & Review System',
    description: 'Implement user rating functionality',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-6-2',
    title: 'Build provider rating algorithm',
    completed: false,
    milestoneId: 'Rating & Review System',
    description: 'Develop provider rating calculation system',
    priority: 'medium',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-7-1',
    title: 'Write unit tests',
    completed: false,
    milestoneId: 'Testing',
    description: 'Create comprehensive unit test suite',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-7-2',
    title: 'Implement end-to-end tests',
    completed: false,
    milestoneId: 'Testing',
    description: 'Set up end-to-end testing framework',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-8-1',
    title: 'Optimize database queries',
    completed: false,
    milestoneId: 'Performance Optimization',
    description: 'Improve database performance',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-8-2',
    title: 'Implement caching system',
    completed: false,
    milestoneId: 'Performance Optimization',
    description: 'Set up application-wide caching',
    priority: 'medium',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-9-1',
    title: 'Perform security audit',
    completed: false,
    milestoneId: 'Security & Compliance',
    description: 'Conduct comprehensive security review',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-9-2',
    title: 'Implement data encryption',
    completed: false,
    milestoneId: 'Security & Compliance',
    description: 'Set up end-to-end data encryption',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-10-1',
    title: 'Conduct user acceptance testing',
    completed: false,
    milestoneId: 'Launch Readiness',
    description: 'Complete UAT with stakeholders',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
  {
    id: 'task-10-2',
    title: 'Create launch checklist',
    completed: false,
    milestoneId: 'Launch Readiness',
    description: 'Prepare comprehensive launch plan',
    priority: 'high',
    status: 'todo',
    progress: 0,
  },
];