import { useState } from 'react';
import { 
  Shield, Activity, Palette, Type, Image, MessageSquare, Copy, Check, 
  AlertCircle, Home, Heart, Zap, Clock, Lock, Grid, Box, Layout,
  Menu, X, ChevronRight
} from 'lucide-react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface Section {
  id: string;
  label: string;
  icon: LucideIcon;
  description?: string;
}

export function BrandIdentity() {
  const [copiedColor, setCopiedColor] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState<string>('brand');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const copyToClipboard = (color: string) => {
    navigator.clipboard.writeText(color);
    setCopiedColor(color);
    setTimeout(() => setCopiedColor(null), 2000);
  };

  const sections: Section[] = [
    { 
      id: 'brand', 
      label: 'Brand Essence', 
      icon: Shield,
      description: 'Core values and brand personality'
    },
    { 
      id: 'colors', 
      label: 'Color Palette', 
      icon: Palette,
      description: 'Primary and secondary colors'
    },
    { 
      id: 'typography', 
      label: 'Typography', 
      icon: Type,
      description: 'Fonts and text styles'
    },
    { 
      id: 'elements', 
      label: 'Design Elements', 
      icon: Layout,
      description: 'Visual components and patterns'
    },
    { 
      id: 'imagery', 
      label: 'Photography', 
      icon: Image,
      description: 'Image style and guidelines'
    },
    { 
      id: 'voice', 
      label: 'Voice & Tone', 
      icon: MessageSquare,
      description: 'Communication style guide'
    },
    { 
      id: 'implementation', 
      label: 'Implementation', 
      icon: Grid,
      description: 'Usage and application'
    }
  ];

  const colors = {
    primary: [
      { name: 'Deep Blue', hex: '#003366', usage: 'Trust, professionalism, stability' },
      { name: 'Emergency Red', hex: '#E63946', usage: 'Urgency, energy, action' },
      { name: 'Calm Grey', hex: '#F7F7F7', usage: 'Balance, neutrality, clarity' }
    ],
    secondary: [
      { name: 'Tech Blue', hex: '#4D9DE0', usage: 'Innovation, connectivity' },
      { name: 'Forest Green', hex: '#2A9D8F', usage: 'Safety, growth' },
      { name: 'Warm Stone', hex: '#E9ECEF', usage: 'Comfort, accessibility' }
    ]
  };

  const brandValues = [
    { icon: Heart, value: 'Reliability', desc: "We're there when you need us most" },
    { icon: Zap, value: 'Speed', desc: 'Quick, efficient response in emergencies' },
    { icon: Lock, value: 'Trust', desc: 'Building confidence through consistent service' },
    { icon: Activity, value: 'Innovation', desc: 'Using technology to enhance emergency response' },
    { icon: MessageSquare, value: 'Empathy', desc: 'Understanding the stress of emergencies' }
  ];

  const designElements = {
    spacing: [
      { label: 'Base unit', value: '8px' },
      { label: 'Scale', value: '8, 16, 24, 32, 48, 64, 96' }
    ],
    radius: [
      { label: 'Small', value: '4px' },
      { label: 'Medium', value: '8px' },
      { label: 'Large', value: '12px' },
      { label: 'Pill', value: '9999px' }
    ],
    shadows: [
      { label: 'Layer 1', value: '0 2px 4px rgba(0, 0, 0, 0.1)' },
      { label: 'Layer 2', value: '0 4px 8px rgba(0, 0, 0, 0.12)' },
      { label: 'Layer 3', value: '0 8px 16px rgba(0, 0, 0, 0.14)' }
    ]
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'brand':
        return (
          <div className="space-y-8">
            {/* Logo Section */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Logo</h2>
              <div className="flex flex-col items-center mb-8">
                <div className="flex items-center justify-center mb-4">
                  <Shield className="h-16 w-16 text-primary" />
                  <Activity className="h-16 w-16 text-accent -ml-3" />
                </div>
                <p className="text-2xl font-bold text-gray-900 font-montserrat">ServiConnect</p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Logo Elements</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center">
                      <Home className="h-4 w-4 mr-2" />
                      House icon (connectivity)
                    </li>
                    <li className="flex items-center">
                      <Shield className="h-4 w-4 mr-2" />
                      Shield element (protection)
                    </li>
                    <li className="flex items-center">
                      <Activity className="h-4 w-4 mr-2" />
                      Pulse line (emergency response)
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Usage Guidelines</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li>• Maintain clear space</li>
                    <li>• Don't alter proportions</li>
                    <li>• Use approved colors only</li>
                    <li>• Ensure legibility</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Brand Values */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Brand Values</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {brandValues.map(({ icon: Icon, value, desc }) => (
                  <div key={value} className="bg-neutral p-4 rounded-lg">
                    <Icon className="h-6 w-6 text-primary mb-2" />
                    <h3 className="font-medium text-gray-900 mb-1">{value}</h3>
                    <p className="text-sm text-gray-600">{desc}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Brand Personality */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Brand Personality</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  { trait: 'Trustworthy', desc: 'Professional, reliable, and competent' },
                  { trait: 'Responsive', desc: 'Quick, alert, and proactive' },
                  { trait: 'Reassuring', desc: 'Calm, confident, and supportive' },
                  { trait: 'Innovative', desc: 'Modern, tech-savvy, but approachable' },
                  { trait: 'Local', desc: 'Connected to the community, culturally aware' }
                ].map((item) => (
                  <div key={item.trait} className="bg-neutral p-4 rounded-lg">
                    <h3 className="font-medium text-gray-900 mb-1">{item.trait}</h3>
                    <p className="text-sm text-gray-600">{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'colors':
        return (
          <div className="space-y-8">
            {/* Primary Colors */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Primary Colors</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {colors.primary.map((color) => (
                  <div key={color.hex} className="bg-white rounded-lg overflow-hidden border border-stone">
                    <div
                      className="h-32 w-full transition-transform hover:scale-105"
                      style={{ backgroundColor: color.hex }}
                    />
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-2">
                        <h3 className="font-medium text-gray-900">{color.name}</h3>
                        <button
                          onClick={() => copyToClipboard(color.hex)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy hex code"
                        >
                          {copiedColor === color.hex ? (
                            <Check className="h-4 w-4 text-success" />
                          ) : (
                            <Copy className="h-4 w-4" />
                          )}
                        </button>
                      </div>
                      <p className="text-sm font-mono text-gray-500 mb-2">{color.hex}</p>
                      <p className="text-sm text-gray-600">{color.usage}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Secondary Colors */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Secondary Colors</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {colors.secondary.map((color) => (
                  <div key={color.hex} className="bg-white rounded-lg overflow-hidden border border-stone">
                    <div
                      className="h-32 w-full transition-transform hover:scale-105"
                      style={{ backgroundColor: color.hex }}
                    />
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-2">
                        <h3 className="font-medium text-gray-900">{color.name}</h3>
                        <button
                          onClick={() => copyToClipboard(color.hex)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy hex code"
                        >
                          {copiedColor === color.hex ? (
                            <Check className="h-4 w-4 text-success" />
                          ) : (
                            <Copy className="h-4 w-4" />
                          )}
                        </button>
                      </div>
                      <p className="text-sm font-mono text-gray-500 mb-2">{color.hex}</p>
                      <p className="text-sm text-gray-600">{color.usage}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'typography':
        return (
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Typography System</h2>
            
            {/* Primary Font */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Primary Font: Montserrat</h3>
              <div className="space-y-6">
                <div className="p-4 bg-neutral rounded-lg">
                  <p className="text-4xl font-bold font-montserrat mb-2">Headlines</p>
                  <p className="text-sm text-gray-600">Montserrat Bold - Used for main headings and titles</p>
                </div>
                <div className="p-4 bg-neutral rounded-lg">
                  <p className="text-2xl font-semibold font-montserrat mb-2">Subheadings</p>
                  <p className="text-sm text-gray-600">Montserrat SemiBold - Used for section headers</p>
                </div>
                <div className="p-4 bg-neutral rounded-lg">
                  <p className="text-base font-montserrat mb-2">Body Text</p>
                  <p className="text-sm text-gray-600">Montserrat Regular - Used for main content</p>
                </div>
              </div>
            </div>

            {/* Secondary Font */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Secondary Font: Open Sans</h3>
              <div className="space-y-6">
                <div className="p-4 bg-neutral rounded-lg">
                  <p className="text-base font-open-sans mb-2">UI Elements</p>
                  <p className="text-sm text-gray-600">Open Sans Regular - Used for interface elements</p>
                </div>
                <div className="p-4 bg-neutral rounded-lg">
                  <p className="text-sm font-light font-open-sans mb-2">Small Text</p>
                  <p className="text-sm text-gray-600">Open Sans Light - Used for captions and metadata</p>
                </div>
              </div>
            </div>
          </div>
        );

      case 'elements':
        return (
          <div className="space-y-8">
            {/* Spacing System */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Spacing System</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {designElements.spacing.map((item) => (
                  <div key={item.label} className="bg-neutral p-4 rounded-lg">
                    <h3 className="font-medium text-gray-900 mb-1">{item.label}</h3>
                    <p className="text-sm font-mono text-gray-600">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Border Radius */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Border Radius</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {designElements.radius.map((item) => (
                  <div key={item.label} className="bg-neutral p-4 rounded-lg">
                    <h3 className="font-medium text-gray-900 mb-1">{item.label}</h3>
                    <p className="text-sm font-mono text-gray-600">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Shadows */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Shadows</h2>
              <div className="grid grid-cols-1 gap-6">
                {designElements.shadows.map((item) => (
                  <div 
                    key={item.label} 
                    className="bg-white p-4 rounded-lg"
                    style={{ boxShadow: item.value }}
                  >
                    <h3 className="font-medium text-gray-900 mb-1">{item.label}</h3>
                    <p className="text-sm font-mono text-gray-600">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'imagery':
        return (
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Photography Guidelines</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="font-medium text-gray-900 mb-4">Style Guidelines</h3>
                <ul className="space-y-3">
                  {[
                    'Natural, authentic situations',
                    'Focus on real people and homes',
                    'Warm, reassuring lighting',
                    'Clear, uncluttered compositions',
                    'Diverse representation',
                    'Urban and suburban settings'
                  ].map((guideline, index) => (
                    <li key={index} className="flex items-start">
                      <span className="text-primary mr-2">•</span>
                      <span className="text-gray-600">{guideline}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h3 className="font-medium text-gray-900 mb-4">Technical Requirements</h3>
                <div className="space-y-4">
                  <div className="p-4 bg-neutral rounded-lg">
                    <h4 className="font-medium text-gray-900 mb-2">Resolution</h4>
                    <p className="text-sm text-gray-600">Minimum 2000px on longest side, 300 DPI</p>
                  </div>
                  <div className="p-4 bg-neutral rounded-lg">
                    <h4 className="font-medium text-gray-900 mb-2">File Format</h4>
                    <p className="text-sm text-gray-600">JPG or PNG for web, TIFF for print</p>
                  </div>
                  <div className="p-4 bg-neutral rounded-lg">
                    <h4 className="font-medium text-gray-900 mb-2">Color Space</h4>
                    <p className="text-sm text-gray-600">sRGB for web, CMYK for print</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'voice':
        return (
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Voice & Tone Guidelines</h2>
            
            {/* Brand Voice */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Brand Voice</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {[
                  { title: 'Clear and direct', desc: 'Use simple, straightforward language' },
                  { title: 'Professionally reassuring', desc: 'Convey expertise with empathy' },
                  { title: 'Knowledgeable but approachable', desc: 'Share expertise without jargon' },
                  { title: 'Solution-focused', desc: 'Emphasize positive outcomes' }
                ].map((item) => (
                  <div key={item.title} className="p-4 bg-neutral rounded-lg">
                    <h4 className="font-medium text-gray-900 mb-1">{item.title}</h4>
                    <p className="text-sm text-gray-600">{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Core Messages */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Core Messages</h3>
              <div className="grid grid-cols-1 gap-4">
                {[
                  {
                    message: "Solving your emergency in one call",
                    example: "We're here 24/7 to handle your emergency needs quickly and efficiently."
                  },
                  {
                    message: "Your trusted emergency response partner",
                    example: "Count on our experienced team to provide reliable solutions when you need them most."
                  },
                  {
                    message: "Professional help when you need it most",
                    example: "Our certified professionals are ready to respond to your emergency situation."
                  },
                  {
                    message: "Local expertise, immediate response",
                    example: "Get connected with trusted local professionals who understand your needs."
                  }
                ].map((item) => (
                  <div key={item.message} className="p-4 bg-neutral rounded-lg">
                    <p className="font-medium text-gray-900 mb-2">{item.message}</p>
                    <p className="text-sm text-gray-600 italic">Example: {item.example}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'implementation':
        return (
          <div className="space-y-8">
            {/* Implementation Guidelines */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Implementation Guidelines</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="font-medium text-gray-900 mb-4">Brand Management</h3>
                  <ul className="space-y-2">
                    {[
                      'Regular brand audits (quarterly)',
                      'Annual brand refresh assessment',
                      'Consistent brand committee oversight',
                      'Local market adaptation protocols',
                      'Partner brand usage guidelines'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start text-gray-600">
                        <span className="text-primary mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                
                <div>
                  <h3 className="font-medium text-gray-900 mb-4">Quality Control</h3>
                  <ul className="space-y-2">
                    {[
                      'Brand usage approval process',
                      'Regular compliance checks',
                      'Design template management',
                      'Asset library maintenance',
                      'Training materials updates'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start text-gray-600">
                        <span className="text-primary mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Cultural Considerations */}
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Cultural Considerations</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {[
                  'Bilingual adaptation (English/French)',
                  'Cultural sensitivity reviews',
                  'Local market customization',
                  'Regional color considerations',
                  'Community-specific imagery'
                ].map((item, index) => (
                  <div key={index} className="p-4 bg-neutral rounded-lg">
                    <p className="text-gray-600">{item}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-0 left-0 right-0 h-[72px] bg-white border-b border-gray-200 px-4 flex items-center justify-between z-20">
        <h1 className="text-xl font-semibold text-gray-900">Brand Identity</h1>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 rounded-md text-gray-500 hover:text-gray-600 hover:bg-gray-100"
        >
          {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </button>
      </div>

      <div className="lg:flex">
        {/* Navigation Sidebar */}
        <nav className={`
          fixed lg:sticky top-0 z-10
          h-full w-64 lg:h-[100vh]
          bg-white border-r border-gray-200
          transform lg:transform-none
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
          transition-transform duration-200 ease-in-out
          overflow-y-auto
          pt-[72px] lg:pt-8
        `}>
          <div className="px-4">
            <div className="space-y-1">
              {sections.map((section) => {
                const Icon = section.icon;
                const isActive = activeSection === section.id;
                
                return (
                  <button
                    key={section.id}
                    onClick={() => {
                      setActiveSection(section.id);
                      setIsMobileMenuOpen(false);
                    }}
                    className={`
                      w-full flex items-start px-3 py-2 text-sm font-medium rounded-lg
                      transition-all duration-200 ease-in-out
                      group relative
                      ${isActive
                        ? 'bg-primary/10 text-primary'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      }
                    `}
                  >
                    <Icon className={`
                      h-5 w-5 mr-3 flex-shrink-0
                      ${isActive ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500'}
                    `} />
                    <div className="flex flex-col items-start min-w-0">
                      <span className="truncate">{section.label}</span>
                      {section.description && (
                        <span className="text-xs text-gray-500 truncate">{section.description}</span>
                      )}
                    </div>
                    {isActive && (
                      <ChevronRight className="absolute right-2 h-5 w-5 text-primary flex-shrink-0" />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className={`
          flex-1 px-4 py-8 lg:px-8
          ${isMobileMenuOpen ? 'blur-sm' : ''}
          transition-all duration-200
          mt-[72px] lg:mt-0
        `}>
          <div className="max-w-7xl mx-auto">
            {/* Content sections */}
            {renderContent()}
          </div>
        </main>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black/20 z-0 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
}