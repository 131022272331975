// Burn Rate Types
export interface BurnRateItem {
  name: string;
  value: number;
  percentage: number;
}

// Sensitivity Analysis Types
export interface SensitivityDataPoint {
  change: string;
  providerAcquisition: number;
  commission: number;
  retention: number;
  frequency: number;
}

// Chart Color Constants
export const CHART_COLORS = {
  primary: '#003366',
  secondary: '#4D9DE0',
  tertiary: '#2A9D8F',
  quaternary: '#E63946',
  neutral: '#E9ECEF'
} as const; 