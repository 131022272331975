
import { Users } from 'lucide-react';

const competitorData = [
  {
    type: 'Traditional Services',
    marketShare: 65,
    advantage: 'AI-powered response time'
  },
  {
    type: 'Digital Platforms',
    marketShare: 25,
    advantage: 'Emergency specialization'
  },
  {
    type: 'New Entrants',
    marketShare: 10,
    advantage: 'Local market focus'
  }
];

export function CompetitiveAnalysisCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Users className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Competitive Analysis</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {competitorData.map((competitor) => (
          <div key={competitor.type} className="bg-gray-50 rounded-lg p-4">
            <h3 className="font-medium text-gray-900 mb-2">{competitor.type}</h3>
            <div className="mb-4">
              <div className="flex justify-between text-sm text-gray-600 mb-1">
                <span>Market Share</span>
                <span>{competitor.marketShare}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-primary h-2 rounded-full"
                  style={{ width: `${competitor.marketShare}%` }}
                />
              </div>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-1">Our Advantage</h4>
              <p className="text-sm text-gray-600">{competitor.advantage}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}