import { DollarSign } from 'lucide-react';
import type { RiskItem } from '../../../types/risk';

const coreRisks: RiskItem[] = [
  {
    risk: 'Revenue Shortfall',
    impact: 'High',
    likelihood: 'Medium',
    mitigation: 'Diversified revenue streams'
  },
  {
    risk: 'Cost Overruns',
    impact: 'Medium',
    likelihood: 'Medium',
    mitigation: 'Strict budget controls'
  },
  {
    risk: 'Market Changes',
    impact: 'High',
    likelihood: 'Low',
    mitigation: 'Adaptive pricing model'
  },
  {
    risk: 'Resource Allocation',
    impact: 'Medium',
    likelihood: 'Low',
    mitigation: 'Resource optimization system'
  }
];

const technologyRisks: RiskItem[] = [
  {
    risk: 'System Downtime',
    impact: 'High',
    likelihood: 'Low',
    mitigation: 'Multi-region redundancy'
  },
  {
    risk: 'Data Loss',
    impact: 'High',
    likelihood: 'Low',
    mitigation: 'Regular backups'
  },
  {
    risk: 'Security Breach',
    impact: 'High',
    likelihood: 'Medium',
    mitigation: 'Enhanced security protocols'
  },
  {
    risk: 'Integration Issues',
    impact: 'Medium',
    likelihood: 'Medium',
    mitigation: 'Comprehensive testing'
  }
];

export function FinancialRiskMatrixCard() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center mb-4">
        <DollarSign className="w-6 h-6 text-blue-500 mr-2" />
        <h2 className="text-xl font-semibold text-gray-900">Financial Risk Matrix</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">Core Business Risks</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Risk
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Impact
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Likelihood
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Mitigation
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {coreRisks.map((risk, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{risk.risk}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          risk.impact === 'High'
                            ? 'bg-red-100 text-red-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {risk.impact}
                      </span>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          risk.likelihood === 'High'
                            ? 'bg-red-100 text-red-800'
                            : risk.likelihood === 'Medium'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-green-100 text-green-800'
                        }`}
                      >
                        {risk.likelihood}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-500">{risk.mitigation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">Technology Risks</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Risk
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Impact
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Likelihood
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Mitigation
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {technologyRisks.map((risk, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{risk.risk}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          risk.impact === 'High'
                            ? 'bg-red-100 text-red-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {risk.impact}
                      </span>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          risk.likelihood === 'High'
                            ? 'bg-red-100 text-red-800'
                            : risk.likelihood === 'Medium'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-green-100 text-green-800'
                        }`}
                      >
                        {risk.likelihood}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-500">{risk.mitigation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}