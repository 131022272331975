import { AlertTriangle } from 'lucide-react';

interface RiskItem {
  category: string;
  impact: 'High' | 'Medium' | 'Low';
  probability: 'High' | 'Medium' | 'Low';
  mitigation: string[];
  monitoring: string[];
}

export function RiskManagementCard() {
  const risks: RiskItem[] = [
    {
      category: 'Provider Network',
      impact: 'High',
      probability: 'Medium',
      mitigation: [
        'Tiered provider incentive structure',
        'Quality-based compensation model',
        'Continuous training program',
        'Performance recognition system'
      ],
      monitoring: [
        'Weekly provider satisfaction surveys',
        'Real-time quality metrics',
        'Churn rate analysis',
        'Provider feedback sessions'
      ]
    },
    {
      category: 'Market Penetration',
      impact: 'High',
      probability: 'Medium',
      mitigation: [
        'Focused Montreal market strategy',
        'Premium segment targeting',
        'Strategic partnerships',
        'Data-driven acquisition'
      ],
      monitoring: [
        'Market share tracking',
        'Competitor analysis',
        'Customer acquisition costs',
        'Conversion rates'
      ]
    },
    {
      category: 'Technology Infrastructure',
      impact: 'High',
      probability: 'Low',
      mitigation: [
        'Scalable cloud architecture',
        'Regular security audits',
        'Redundancy systems',
        'Continuous integration/deployment'
      ],
      monitoring: [
        'System uptime metrics',
        'Response time tracking',
        'Error rate monitoring',
        'Load testing results'
      ]
    },
    {
      category: 'Financial Performance',
      impact: 'High',
      probability: 'Medium',
      mitigation: [
        'Conservative cash management',
        'Milestone-based spending',
        'Regular financial reviews',
        'Strategic contingency fund'
      ],
      monitoring: [
        'Burn rate tracking',
        'Revenue metrics',
        'Margin analysis',
        'Cash flow projections'
      ]
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <AlertTriangle className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Risk Management Framework</h2>
      </div>

      <div className="space-y-6">
        {risks.map((risk) => (
          <div key={risk.category} className="border rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">{risk.category}</h3>
              <div className="flex gap-4">
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                  risk.impact === 'High' 
                    ? 'bg-red-100 text-red-800'
                    : risk.impact === 'Medium'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-green-100 text-green-800'
                }`}>
                  Impact: {risk.impact}
                </span>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                  risk.probability === 'High'
                    ? 'bg-red-100 text-red-800'
                    : risk.probability === 'Medium'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-green-100 text-green-800'
                }`}>
                  Probability: {risk.probability}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500 mb-2">Mitigation Strategy</h4>
                <ul className="space-y-1">
                  {risk.mitigation.map((item, index) => (
                    <li key={index} className="text-sm text-gray-600">• {item}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500 mb-2">Monitoring Metrics</h4>
                <ul className="space-y-1">
                  {risk.monitoring.map((item, index) => (
                    <li key={index} className="text-sm text-gray-600">• {item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
