'use client';

import { DollarSign } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const generateUnitEconomicsData = () => {
  const data = [];
  const cac = 200;
  const monthlyValue = 50;

  for (let month = 0; month <= 12; month++) {
    data.push({
      month: `Month ${month}`,
      cac: cac,
      ltv: month * monthlyValue,
    });
  }

  return data;
};

export function UnitEconomicsCard() {
  const data = generateUnitEconomicsData();

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <DollarSign className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Unit Economics</h2>
      </div>

      <div className="h-80 mb-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip formatter={(value: number) => `$${value}`} />
            <Legend />
            <Line
              type="monotone"
              dataKey="cac"
              name="Customer Acquisition Cost"
              stroke="#E63946"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="ltv"
              name="Customer Lifetime Value"
              stroke="#2A9D8F"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {[
          { label: 'Initial CAC', value: '$200' },
          { label: 'Average LTV (12-month)', value: '$600' },
          { label: 'LTV/CAC Ratio', value: '3:1' },
          { label: 'Payback Period', value: '4 months' },
        ].map((metric) => (
          <div key={metric.label} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-1">{metric.label}</h3>
            <p className="text-2xl font-bold text-gray-900">{metric.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}