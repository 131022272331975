'use client';

import { Sprout } from 'lucide-react';

export function SeedInvestmentCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Sprout className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Seed Investment Requirements</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">Initial Capital Needs</h3>
          <p className="text-gray-600">
            The outlined cost structure is intended to support an early-stage MVP. 
            The seed investment of approximately $200,000 will cover development, 
            marketing, and operational expenses for the first year, helping to 
            establish market fit.
          </p>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">Clear Path to Scalability</h3>
          <p className="text-gray-600">
            Early investments will focus on infrastructure and market entry, 
            ensuring the business is well-positioned for Series A growth. 
            Demonstrating traction through effective cost management and initial 
            customer acquisition will be key for future funding.
          </p>
        </div>

        <div className="mt-6 bg-blue-50 rounded-lg p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">Investment Allocation</h3>
              <div className="mt-2 text-sm text-blue-700">
                <ul className="list-disc pl-5 space-y-1">
                  <li>Development and Infrastructure: 50%</li>
                  <li>Marketing and Customer Acquisition: 30%</li>
                  <li>Operations and Overhead: 20%</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 