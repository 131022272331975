import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export interface User {
  id: string;
  email: string;
  role: string;
  name?: string;
  created_at: string;
  last_sign_in_at: string | null;
  status?: 'active' | 'pending' | 'inactive';
}

export interface Invitation {
  id: string;
  email: string;
  role: string;
  created_at: string;
  expires_at: string;
  status: 'pending' | 'accepted' | 'expired';
}

export interface Profile {
  id: string;
  role: string;
  email: string;
  name?: string;
  created_at: string;
  updated_at: string;
}
