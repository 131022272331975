import { useState, useEffect } from 'react';
import { 
  ChevronRight, BookOpen, Code, Shield, Server, 
  Database, Wrench, Search, ChevronDown, Menu, X 
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

interface DocSection {
  id: string;
  title: string;
  icon: React.ElementType;
  content: string;
  description?: string;
  children?: { title: string; content: string }[];
}

export function Documentation() {
  const [selectedDoc, setSelectedDoc] = useState<string>('readme');
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedChild, setExpandedChild] = useState<string | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const docs: DocSection[] = [
    {
      id: 'readme',
      title: 'README',
      icon: BookOpen,
      description: 'Project overview and setup instructions',
      content: `# ServiConnect

ServiConnect is an innovative emergency service platform that connects users with healthcare providers in real-time through AI-powered triage and matching technology.

## Key Features

- AI-powered triage system
- Real-time provider matching
- Secure payment processing
- Emergency response tracking
- Provider rating system

## Technology Stack

- Frontend: React with TypeScript
- Backend: Node.js with FastAPI
- Database: PostgreSQL
- AI/ML: TensorFlow
- Real-time: WebSocket
- Payment: Stripe Integration

## Getting Started

Detailed setup instructions in the Installation Guide.`,
      children: [
        {
          title: 'Installation',
          content: 'Step-by-step installation guide...',
        },
        {
          title: 'Configuration',
          content: 'Configuration options and environment setup...',
        },
      ],
    },
    {
      id: 'architecture',
      title: 'Architecture',
      icon: Server,
      description: 'System design and component interactions',
      content: `# System Architecture

## Overview

ServiConnect uses a microservices architecture for scalability and maintainability.

## Components

- API Gateway
- Authentication Service
- Triage Service
- Matching Service
- Payment Service
- Notification Service

## Data Flow

Detailed data flow diagrams and service interactions...`,
    },
    {
      id: 'api',
      title: 'API Documentation',
      icon: Code,
      description: 'API endpoints and usage examples',
      content: `# API Documentation

## REST Endpoints

### Authentication
- POST /api/auth/login
- POST /api/auth/register

### Triage
- POST /api/triage/start
- PUT /api/triage/update

### Providers
- GET /api/providers
- POST /api/providers/match

## WebSocket Events

Real-time event documentation...`,
    },
    {
      id: 'security',
      title: 'Security',
      icon: Shield,
      description: 'Security guidelines and best practices',
      content: `# Security Guidelines

## Authentication

- JWT-based authentication
- Role-based access control
- Session management

## Data Protection

- End-to-end encryption
- HIPAA compliance
- Data retention policies

## Security Best Practices

Implementation guidelines...`,
    },
    {
      id: 'database',
      title: 'Database Schema',
      icon: Database,
      description: 'Database structure and relationships',
      content: `# Database Schema

## Tables

### Users
- id: UUID
- email: VARCHAR
- role: ENUM
- created_at: TIMESTAMP

### Providers
- id: UUID
- specialization: VARCHAR
- availability: JSONB

### Emergency Cases
- id: UUID
- status: ENUM
- priority: INTEGER

## Relationships

Detailed entity relationships...`,
    },
    {
      id: 'deployment',
      title: 'Deployment',
      icon: Wrench,
      description: 'Deployment and CI/CD pipeline',
      content: `# Deployment Guide

## Prerequisites

- Docker
- Kubernetes
- Cloud Provider Access

## Environment Setup

1. Development
2. Staging
3. Production

## CI/CD Pipeline

Deployment workflow documentation...`,
    },
  ];

  const filteredDocs = docs.filter(doc =>
    doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doc.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-[calc(100vh-8rem)]">
      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-0 left-0 right-0 z-20 bg-white border-b border-gray-200 p-4">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="flex items-center justify-center p-2 rounded-lg text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
          aria-expanded={isMobileMenuOpen}
        >
          {isMobileMenuOpen ? (
            <X className="h-6 w-6" aria-hidden="true" />
          ) : (
            <Menu className="h-6 w-6" aria-hidden="true" />
          )}
          <span className="ml-2">Menu</span>
        </button>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <h1 className="text-3xl font-bold text-gray-900 mb-4">Documentation</h1>
          <p className="text-lg text-gray-600">
            Comprehensive guide to ServiConnect's architecture, APIs, and development practices.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Sidebar Navigation */}
          <nav className={`
            ${isMobileMenuOpen ? 'block' : 'hidden'}
            lg:block
            fixed lg:relative
            top-[72px] lg:top-0
            left-0
            w-full lg:w-auto
            h-[calc(100vh-72px)] lg:h-auto
            bg-white
            lg:bg-transparent
            z-10
            overflow-y-auto
            lg:overflow-visible
            transition-all
            duration-200
            ease-in-out
          `}>
            <div className="p-4 lg:p-0 space-y-2">
              <div className="relative mb-4">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search documentation..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary transition-colors"
                />
              </div>

              <AnimatePresence>
                {filteredDocs.map((doc) => {
                  const Icon = doc.icon;
                  const isActive = selectedDoc === doc.id;
                  const hasChildren = doc.children && doc.children.length > 0;

                  return (
                    <motion.div
                      key={doc.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      className="space-y-1"
                    >
                      <button
                        onClick={() => {
                          setSelectedDoc(doc.id);
                          setIsMobileMenuOpen(false);
                        }}
                        className={`
                          w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg
                          transition-all duration-200
                          group relative
                          ${isActive
                            ? 'bg-primary/10 text-primary'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                          }
                        `}
                      >
                        <Icon className={`
                          h-5 w-5 mr-3
                          transition-colors
                          ${isActive ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500'}
                        `} />
                        <div className="flex-1 text-left">
                          <span>{doc.title}</span>
                          {doc.description && (
                            <p className="text-xs text-gray-500">{doc.description}</p>
                          )}
                        </div>
                        {hasChildren && (
                          <ChevronDown
                            className={`
                              h-5 w-5 ml-2
                              transition-transform
                              ${expandedChild === doc.id ? 'transform rotate-180' : ''}
                            `}
                            onClick={(e) => {
                              e.stopPropagation();
                              setExpandedChild(expandedChild === doc.id ? null : doc.id);
                            }}
                          />
                        )}
                        {isActive && (
                          <ChevronRight className="absolute right-2 h-5 w-5 text-primary" />
                        )}
                      </button>

                      {/* Children */}
                      {hasChildren && expandedChild === doc.id && (
                        <div className="ml-6 space-y-1">
                          {doc.children?.map((child) => (
                            <button
                              key={child.title}
                              onClick={() => {
                                // Handle child click
                              }}
                              className="
                                w-full flex items-center px-3 py-2 text-sm
                                text-gray-600 hover:text-gray-900
                                hover:bg-gray-50 rounded-lg
                                transition-colors
                              "
                            >
                              <span className="w-1.5 h-1.5 rounded-full bg-gray-400 mr-3" />
                              {child.title}
                            </button>
                          ))}
                        </div>
                      )}
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
          </nav>

          {/* Main Content */}
          <main className={`
            lg:col-span-3
            ${isMobileMenuOpen ? 'hidden lg:block' : 'block'}
          `}>
            <AnimatePresence mode="wait">
              <motion.div
                key={selectedDoc}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="prose prose-primary max-w-none"
              >
                <div className="bg-white rounded-xl shadow-sm p-6 md:p-8">
                  <ReactMarkdown>
                    {docs.find(d => d.id === selectedDoc)?.content || ''}
                  </ReactMarkdown>
                </div>
              </motion.div>
            </AnimatePresence>
          </main>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/20 z-0 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
}