import { Calculator } from 'lucide-react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

interface ProfitabilityScenario {
  month: string;
  revenue: number;
  expenses: number;
  profit: number;
}

interface MonthlyProjection {
  revenue: number;
  costs: number;
  profit: number;
  margin: number;
}

interface OperationalCosts {
  insurance: {
    base: number;
    liability: number;
    source: string;
  };
  licenses: {
    RBQ: number;
    municipal: number;
    source: string;
  };
  equipment: {
    maintenance: number;
    replacement: number;
    winterization: number;
    source: string;
  };
  transportation: {
    fuel: number;
    maintenance: number;
    parking: number;
    source: string;
  };
}

interface ServiceMargins {
  emergency: {
    standard: number;
    afterHours: number;
    holiday: number;
    source: string;
  };
  maintenance: {
    standard: number;
    contract: number;
    source: string;
  };
  seasonal: {
    summer: number;
    winter: number;
    source: string;
  };
}

export function ProviderProfitabilityCard() {
  // Montreal market-specific operational costs
  const operationalCosts: OperationalCosts = {
    insurance: {
      base: 2400,
      liability: 1800,
      source: 'Insurance Bureau of Canada'
    },
    licenses: {
      RBQ: 956,
      municipal: 350,
      source: 'RBQ Fee Schedule 2023'
    },
    equipment: {
      maintenance: 2400,
      replacement: 3600,
      winterization: 1200,
      source: 'APCHQ Cost Analysis'
    },
    transportation: {
      fuel: 3600,
      maintenance: 2400,
      parking: 1200,
      source: 'CAA Quebec Operating Costs'
    }
  };

  // Service-specific profit margins
  const serviceMargins: ServiceMargins = {
    emergency: {
      standard: 0.45,
      afterHours: 0.55,
      holiday: 0.65,
      source: 'Montreal Emergency Services Association'
    },
    maintenance: {
      standard: 0.35,
      contract: 0.40,
      source: 'APCHQ Pricing Guidelines'
    },
    seasonal: {
      summer: 0.30,
      winter: 0.40,
      source: 'Quebec Seasonal Services Report'
    }
  };

  // Calculate monthly profitability with enhanced factors
  const calculateMonthlyProfit = (month: number, serviceVolume: number): MonthlyProjection => {
    const seasonalMultiplier = 1 + Math.sin((month - 1) * Math.PI / 6) * 0.2; // Seasonal variation
    const isWinterMonth = month >= 11 || month <= 2;
    
    // Base revenue calculation
    const baseRevenue = serviceVolume * 175 * seasonalMultiplier;
    
    // Additional winter revenue for emergency services
    const winterPremium = isWinterMonth ? baseRevenue * 0.2 : 0;
    
    // Monthly operational costs
    const monthlyFixed = (
      operationalCosts.insurance.base +
      operationalCosts.insurance.liability +
      operationalCosts.licenses.RBQ +
      operationalCosts.licenses.municipal
    ) / 12;
    
    const monthlyVariable = (
      operationalCosts.equipment.maintenance +
      operationalCosts.equipment.replacement +
      (isWinterMonth ? operationalCosts.equipment.winterization : 0) +
      operationalCosts.transportation.fuel +
      operationalCosts.transportation.maintenance +
      operationalCosts.transportation.parking
    ) / 12;

    // Service mix profitability
    const emergencyShare = isWinterMonth ? 0.5 : 0.3;
    const maintenanceShare = isWinterMonth ? 0.3 : 0.5;
    const seasonalShare = 0.2;

    const weightedMargin = (
      emergencyShare * (isWinterMonth ? serviceMargins.emergency.afterHours : serviceMargins.emergency.standard) +
      maintenanceShare * serviceMargins.maintenance.standard +
      seasonalShare * (isWinterMonth ? serviceMargins.seasonal.winter : serviceMargins.seasonal.summer)
    );

    const totalRevenue = baseRevenue + winterPremium;
    const totalCosts = monthlyFixed + monthlyVariable;
    const profit = (totalRevenue * weightedMargin) - totalCosts;

    return {
      revenue: totalRevenue,
      costs: totalCosts,
      profit: profit,
      margin: (profit / totalRevenue) * 100
    };
  };

  // Generate monthly scenarios
  const profitabilityScenarios = Array.from({ length: 12 }, (_, month) => {
    const projection = calculateMonthlyProfit(month, 20);
    return {
      month: new Date(2023, month).toLocaleString('default', { month: 'short' }),
      revenue: Math.round(projection.revenue),
      expenses: Math.round(projection.costs),
      profit: Math.round(projection.profit)
    };
  });

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Calculator className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Provider Profitability Analysis</h2>
      </div>

      {/* Monthly Projections */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Monthly Performance Projection</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Array.from({ length: 12 }).map((_, month) => {
            const projection = calculateMonthlyProfit(month, 20);
            return (
              <div key={month} className="bg-gray-50 rounded-lg p-4">
                <h4 className="text-sm font-medium text-gray-500 mb-2">
                  {new Date(2023, month).toLocaleString('default', { month: 'long' })}
                </h4>
                <div className="space-y-2">
                  <p className="text-sm text-gray-600">
                    Revenue: <span className="font-medium">${Math.round(projection.revenue)}</span>
                  </p>
                  <p className="text-sm text-gray-600">
                    Costs: <span className="font-medium">${Math.round(projection.costs)}</span>
                  </p>
                  <p className="text-sm text-gray-600">
                    Profit: <span className="font-medium">${Math.round(projection.profit)}</span>
                  </p>
                  <p className="text-sm text-gray-600">
                    Margin: <span className="font-medium">{projection.margin.toFixed(1)}%</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Cost Breakdown */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Operational Cost Structure</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {(Object.entries(operationalCosts) as [keyof OperationalCosts, Record<string, number | string>][]).map(([category, costs]) => (
            <div key={category} className="bg-gray-50 rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-500 capitalize mb-2">{category}</h4>
              <div className="space-y-2">
                {Object.entries(costs).map(([item, value]) => (
                  item !== 'source' && (
                    <p key={item} className="text-sm text-gray-600">
                      {item.charAt(0).toUpperCase() + item.slice(1)}: ${value}/year
                    </p>
                  )
                ))}
                <p className="text-xs text-gray-400">Source: {costs.source as string}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Service Margins */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Service Type Margins</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {(Object.entries(serviceMargins) as [keyof ServiceMargins, Record<string, number | string>][]).map(([category, margins]) => (
            <div key={category} className="bg-gray-50 rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-500 capitalize mb-2">{category}</h4>
              <div className="space-y-2">
                {Object.entries(margins).map(([type, margin]) => (
                  type !== 'source' && (
                    <p key={type} className="text-sm text-gray-600">
                      {type.charAt(0).toUpperCase() + type.slice(1)}: {((margin as number) * 100).toFixed(1)}%
                    </p>
                  )
                ))}
                <p className="text-xs text-gray-400">Source: {margins.source as string}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Monthly Projection Chart */}
      <div className="h-80 mb-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={profitabilityScenarios}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="revenue" stroke="#4F46E5" strokeWidth={2} />
            <Line type="monotone" dataKey="expenses" stroke="#EF4444" strokeWidth={2} />
            <Line type="monotone" dataKey="profit" stroke="#10B981" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
