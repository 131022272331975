
import { Users } from 'lucide-react';

const phases = [
  {
    title: 'Phase 1 (Months 1-6)',
    items: [
      'Solo Development',
      'Cost: $0 (Personal Time Investment)'
    ]
  },
  {
    title: 'Phase 2 (Months 7-12)',
    items: [
      'Freelance Support',
      'Customer Service (Part-time): $1,000/month',
      'Technical Support (As needed): $2,000/month'
    ]
  },
  {
    title: 'Phase 3 (Months 13-18)',
    items: [
      'First Hires',
      'Customer Success Manager: $45,000/year',
      'Technical Lead: $75,000/year'
    ]
  }
];

export function StaffingTimelineCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Users className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Staffing Timeline (Initial 18 Months)</h2>
      </div>
      <div className="space-y-8">
        {phases.map((phase, index) => (
          <div key={index} className="relative pl-8">
            <div className="absolute left-0 top-0 h-full">
              <div className="h-full w-px bg-gray-200"></div>
            </div>
            <div className="absolute left-0 top-2 w-2 h-2 rounded-full bg-primary"></div>
            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-900">{phase.title}</h3>
              <ul className="mt-2 space-y-2">
                {phase.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="text-gray-600 flex items-start">
                    <span className="text-primary mr-2">•</span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}