import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { 
  FileText, Palette, Users, BookOpen, 
  Presentation, DollarSign, Menu, X, Settings, Shield,
  LogOut
} from 'lucide-react';
import { useSession } from '@supabase/auth-helpers-react';
import { useStore } from '../store';
import { LanguageSwitcher } from './LanguageSwitcher';

export function Header() {
  const session = useSession();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { logout } = useStore();
  const intl = useIntl();

  // Close mobile menu on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tabs = [
    { 
      id: 'overview', 
      path: '/dashboard', 
      name: intl.formatMessage({ id: 'overview' }),
      icon: Presentation
    },
    { 
      id: 'business-plan', 
      path: '/dashboard/business-plan', 
      name: intl.formatMessage({ id: 'business_plan' }),
      icon: FileText 
    },
    { 
      id: 'financials', 
      path: '/dashboard/financials', 
      name: intl.formatMessage({ id: 'financials' }),
      icon: DollarSign 
    },
    { 
      id: 'brand-identity', 
      path: '/dashboard/brand-identity', 
      name: intl.formatMessage({ id: 'brand_identity' }),
      icon: Palette 
    },
    { 
      id: 'team', 
      path: '/dashboard/team', 
      name: intl.formatMessage({ id: 'team' }),
      icon: Users 
    },
    { 
      id: 'documentation', 
      path: '/dashboard/documentation', 
      name: intl.formatMessage({ id: 'documentation' }),
      icon: BookOpen 
    }
  ];

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 z-50 shadow-sm">
        <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <NavLink to="/dashboard" className="text-xl font-bold text-indigo-600">
                ServiConnect
              </NavLink>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex md:items-center md:space-x-6 flex-1 justify-start pl-8">
              <nav className="flex space-x-4">
                {tabs.map((tab) => {
                  const Icon = tab.icon;
                  return (
                    <NavLink
                      key={tab.id}
                      to={tab.path}
                      className={({ isActive }) =>
                        `flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors duration-150 text-left min-w-[160px] ${
                          isActive
                            ? 'bg-indigo-100 text-indigo-700'
                            : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                        }`
                      }
                    >
                      <Icon className="w-5 h-5 mr-3 flex-shrink-0" />
                      <span className="whitespace-nowrap">{tab.name}</span>
                    </NavLink>
                  );
                })}
              </nav>
            </div>

            {/* Right side items */}
            <div className="flex items-center space-x-4">
              <LanguageSwitcher />
              
              {/* Settings & Admin Links */}
              <div className="hidden md:flex items-center space-x-2">
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    `p-2 rounded-md transition-colors duration-150 ${
                      isActive ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-100'
                    }`
                  }
                >
                  <Settings className="w-5 h-5" />
                </NavLink>
                
                {session?.user?.email === 'admin@serviconnect.com' && (
                  <NavLink
                    to="/admin"
                    className={({ isActive }) =>
                      `p-2 rounded-md transition-colors duration-150 ${
                        isActive ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-100'
                      }`
                    }
                  >
                    <Shield className="w-5 h-5" />
                  </NavLink>
                )}
              </div>

              {/* Mobile menu button */}
              <button
                type="button"
                className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <span className="sr-only">
                  {isMobileMenuOpen ? 'Close menu' : 'Open menu'}
                </span>
                {isMobileMenuOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white border-b border-gray-200">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <NavLink
                  key={tab.id}
                  to={tab.path}
                  className={({ isActive }) =>
                    `flex items-center px-3 py-2 text-base font-medium rounded-md w-full text-left ${
                      isActive
                        ? 'bg-indigo-100 text-indigo-700'
                        : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                    }`
                  }
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Icon className="w-5 h-5 mr-3 flex-shrink-0" />
                  <span className="whitespace-nowrap">{tab.name}</span>
                </NavLink>
              );
            })}

            {/* Mobile Settings & Admin */}
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-base font-medium rounded-md w-full text-left ${
                  isActive
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                }`
              }
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <Settings className="w-5 h-5 mr-3 flex-shrink-0" />
              <span className="whitespace-nowrap">{intl.formatMessage({ id: 'settings' })}</span>
            </NavLink>

            {session?.user?.email === 'admin@serviconnect.com' && (
              <NavLink
                to="/admin"
                className={({ isActive }) =>
                  `flex items-center px-3 py-2 text-base font-medium rounded-md w-full text-left ${
                    isActive
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                  }`
                }
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <Shield className="w-5 h-5 mr-3 flex-shrink-0" />
                <span className="whitespace-nowrap">{intl.formatMessage({ id: 'admin' })}</span>
              </NavLink>
            )}

            {/* Logout in mobile menu */}
            <button
              onClick={() => {
                setIsMobileMenuOpen(false);
                logout();
              }}
              className="flex items-center px-3 py-2 text-base font-medium rounded-md w-full text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <LogOut className="w-5 h-5 mr-3 flex-shrink-0" />
              <span className="whitespace-nowrap">{intl.formatMessage({ id: 'logout' })}</span>
            </button>
          </div>
        </div>
      </header>
      
      {/* Spacer to prevent content from going under fixed header */}
      <div className="h-16" />
    </>
  );
}