import { ShieldCheck } from 'lucide-react';
import type { InsuranceCoverage } from '../../../types/risk';

const insurancePolicies: InsuranceCoverage[] = [
  {
    type: 'Professional Liability',
    coverage: '$2,000,000',
    provider: 'Chubb',
    status: 'Active'
  },
  {
    type: 'Cyber Security',
    coverage: '$5,000,000',
    provider: 'AIG',
    status: 'Active'
  },
  {
    type: 'General Liability',
    coverage: '$1,000,000',
    provider: 'Travelers',
    status: 'Active'
  },
  {
    type: 'Business Interruption',
    coverage: '$3,000,000',
    provider: 'Hartford',
    status: 'Pending'
  }
];

export function InsuranceComplianceCard() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center mb-4">
        <ShieldCheck className="w-6 h-6 text-blue-500 mr-2" />
        <h2 className="text-xl font-semibold text-gray-900">Insurance Coverage</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-3">Active Policies</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Coverage
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Provider
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {insurancePolicies.map((policy, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{policy.type}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{policy.coverage}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{policy.provider}</td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          policy.status === 'Active'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {policy.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-blue-50 rounded-lg p-4">
          <h4 className="text-sm font-medium text-blue-800 mb-2">Coverage Notes</h4>
          <ul className="text-sm text-blue-700 space-y-1">
            <li>• All policies include worldwide coverage where applicable</li>
            <li>• Cyber Security policy includes first-party and third-party coverage</li>
            <li>• Professional Liability covers both service delivery and technology errors</li>
            <li>• Business Interruption includes coverage for cloud service disruption</li>
          </ul>
        </div>
      </div>
    </div>
  );
}