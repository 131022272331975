
import { 
  Target, TrendingUp, Users, Rocket, DollarSign, 
  Map, Award, BarChart, Building, Shield 
} from 'lucide-react';

export function Presentation() {
  const sections = [
    {
      title: 'Project Vision',
      icon: Target,
      content: `ServyConnect aims to become the go-to platform for emergency home services by connecting users 
      with reliable service providers quickly and efficiently. The platform prioritizes trust, speed, and a 
      seamless user experience to ensure that users in urgent situations receive timely help.`
    },
    {
      title: 'Core Features',
      icon: Shield,
      features: [
        { name: 'Service Request System', desc: 'Users can easily submit emergency requests' },
        { name: 'Provider Matching', desc: 'Automated matching based on availability and location' },
        { name: 'User and Provider Profiles', desc: 'Comprehensive onboarding processes' },
        { name: 'Ratings and Reviews', desc: 'Feedback system to maintain service quality' },
        { name: 'Payment Integration', desc: 'Secure payment processing for completed services' }
      ]
    },
    {
      title: 'MVP Launch Strategy',
      icon: Rocket,
      items: [
        'Controlled Rollout in specific neighborhoods',
        'Community Engagement through local partnerships',
        'Iterative development based on user feedback',
        'Focus on service quality and reliability'
      ]
    },
    {
      title: 'Monetization Model',
      icon: DollarSign,
      approaches: [
        { name: 'Pay-Per-Use Fees', desc: 'Charge users for individual service requests' },
        { name: 'Subscription Tiers', desc: 'Premium plans with priority access' },
        { name: 'Surge Pricing', desc: 'Dynamic pricing for high-demand periods' },
        { name: 'Business Plans', desc: 'Subscriptions for property managers' }
      ]
    },
    {
      title: 'Growth Strategy',
      icon: TrendingUp,
      phases: [
        {
          title: 'Initial Team Expansion',
          items: [
            'Customer Support hiring',
            'Provider Management team',
            'Technical team growth'
          ]
        },
        {
          title: 'Scaling Operations',
          items: [
            'Standard Operating Procedures',
            'Localized Marketing Campaigns',
            'Regional expansion planning'
          ]
        }
      ]
    },
    {
      title: 'Funding & Milestones',
      icon: BarChart,
      strategy: {
        initial: [
          'Bootstrap MVP development',
          'Utilize personal funds and job income'
        ],
        growth: [
          'Seek external funding post-validation',
          'Target angel investors and micro-VCs'
        ],
        milestones: [
          'Achieve sustainable monthly revenue',
          'Expand user base consistently',
          'Scale operations in new markets'
        ]
      }
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="text-center mb-12">
        <div className="flex items-center justify-center mb-4">
          <Shield className="h-12 w-12 text-primary" />
          <Building className="h-12 w-12 text-accent -ml-2" />
        </div>
        <h1 className="text-4xl font-bold text-gray-900 mb-4">ServyConnect Project Overview</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Revolutionizing emergency home services through innovative technology and reliable connections.
        </p>
      </div>

      <div className="space-y-12">
        {sections.map((section) => {
          const Icon = section.icon;
          return (
            <div key={section.title} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="flex items-center gap-3 mb-6">
                  <Icon className="h-8 w-8 text-primary" />
                  <h2 className="text-2xl font-bold text-gray-900">{section.title}</h2>
                </div>

                {section.content && (
                  <p className="text-gray-600 leading-relaxed">{section.content}</p>
                )}

                {section.features && (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
                    {section.features.map((feature) => (
                      <div key={feature.name} className="bg-neutral p-4 rounded-lg">
                        <h3 className="font-semibold text-gray-900 mb-2">{feature.name}</h3>
                        <p className="text-sm text-gray-600">{feature.desc}</p>
                      </div>
                    ))}
                  </div>
                )}

                {section.items && (
                  <ul className="mt-4 space-y-2">
                    {section.items.map((item, index) => (
                      <li key={index} className="flex items-start">
                        <span className="text-primary mr-2">•</span>
                        <span className="text-gray-600">{item}</span>
                      </li>
                    ))}
                  </ul>
                )}

                {section.approaches && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    {section.approaches.map((approach) => (
                      <div key={approach.name} className="bg-neutral p-4 rounded-lg">
                        <h3 className="font-semibold text-gray-900 mb-1">{approach.name}</h3>
                        <p className="text-sm text-gray-600">{approach.desc}</p>
                      </div>
                    ))}
                  </div>
                )}

                {section.phases && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                    {section.phases.map((phase) => (
                      <div key={phase.title} className="bg-neutral p-4 rounded-lg">
                        <h3 className="font-semibold text-gray-900 mb-3">{phase.title}</h3>
                        <ul className="space-y-2">
                          {phase.items.map((item, index) => (
                            <li key={index} className="flex items-start text-sm text-gray-600">
                              <span className="text-primary mr-2">•</span>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}

                {section.strategy && (
                  <div className="space-y-6 mt-4">
                    <div className="bg-neutral p-4 rounded-lg">
                      <h3 className="font-semibold text-gray-900 mb-3">Initial Funding</h3>
                      <ul className="space-y-2">
                        {section.strategy.initial.map((item, index) => (
                          <li key={index} className="flex items-start text-sm text-gray-600">
                            <span className="text-primary mr-2">•</span>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="bg-neutral p-4 rounded-lg">
                      <h3 className="font-semibold text-gray-900 mb-3">Growth Funding</h3>
                      <ul className="space-y-2">
                        {section.strategy.growth.map((item, index) => (
                          <li key={index} className="flex items-start text-sm text-gray-600">
                            <span className="text-primary mr-2">•</span>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="bg-neutral p-4 rounded-lg">
                      <h3 className="font-semibold text-gray-900 mb-3">Key Milestones</h3>
                      <ul className="space-y-2">
                        {section.strategy.milestones.map((item, index) => (
                          <li key={index} className="flex items-start text-sm text-gray-600">
                            <span className="text-primary mr-2">•</span>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}