import { Brain } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const usageTiers = [
  {
    segment: 'MVP Launch',
    tokens: '10M',
    costPerK: 0.02,
    totalCost: 200,
    features: 'Emergency Triage Only'
  },
  {
    segment: 'Early Growth',
    tokens: '25M',
    costPerK: 0.02,
    totalCost: 500,
    features: '+Response Optimization'
  },
  {
    segment: 'Market Fit',
    tokens: '50M',
    costPerK: 0.02,
    totalCost: 1000,
    features: '+Provider Matching'
  },
  {
    segment: 'Scale',
    tokens: '100M',
    costPerK: 0.02,
    totalCost: 2000,
    features: '+Predictive Analytics'
  }
];

// Generate projection data for the chart
const projectionData = usageTiers.map((tier) => ({
  name: tier.segment,
  tokens: parseInt(tier.tokens.replace('M', '')) * 1000000,
  cost: tier.totalCost
}));

export function TokenProjectionsCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Brain className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Monthly Token Usage Projections</h2>
      </div>

      {/* Usage Chart */}
      <div className="h-80 mb-8">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={projectionData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="tokens"
              name="Token Usage"
              stroke="#003366"
              strokeWidth={2}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="cost"
              name="Cost ($)"
              stroke="#2A9D8F"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Usage Tiers Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User Segment
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Monthly Tokens
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cost/1K Tokens
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total Cost
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Features
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {usageTiers.map((tier) => (
              <tr key={tier.segment}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {tier.segment}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {tier.tokens}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${tier.costPerK}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${tier.totalCost}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {tier.features}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
} 