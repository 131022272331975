import { TrendingUp } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const marketingAllocation = [
  { name: 'SEO & Content', value: 4800, percentage: 40 },
  { name: 'Local Google Ads', value: 3600, percentage: 30 },
  { name: 'Social Media', value: 2400, percentage: 20 },
  { name: 'PR & Events', value: 1200, percentage: 10 }
];

const COLORS = ['#003366', '#4D9DE0', '#2A9D8F', '#E9ECEF'];

export function MarketingAllocationCard() {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <TrendingUp className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Marketing Budget (Year 1)</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={marketingAllocation}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {marketingAllocation.map((_, i) => (
                  <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [`$${value.toLocaleString()}`, 'Budget']}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          {marketingAllocation.map((item, index) => (
            <div key={item.name} className="flex justify-between items-center">
              <div className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index] }}
                />
                <span className="text-gray-600">{item.name}</span>
              </div>
              <div className="text-right">
                <span className="font-semibold">${item.value.toLocaleString()}</span>
                <span className="text-gray-500 text-sm ml-2">({item.percentage}%)</span>
              </div>
            </div>
          ))}
          <div className="pt-4 border-t">
            <div className="flex justify-between items-center font-semibold">
              <span>Total Annual Budget</span>
              <span>${marketingAllocation.reduce((acc, curr) => acc + curr.value, 0).toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 