import { Target } from 'lucide-react';

export function MarketSizeCard() {
  const marketData = {
    tam: {
      value: '1.2B CAD',
      description: 'Total Quebec Property Management Services Market'
    },
    sam: {
      value: '350M CAD',
      description: 'Montreal Metropolitan Area Property Services'
    },
    som: {
      value: '21.15M CAD',
      description: 'Premium Property Management Segment in Montreal'
    }
  };

  const marketInsights = [
    {
      title: 'Premium Segment Focus',
      value: '25 Premium Providers',
      trend: 'Initial Network Target'
    },
    {
      title: 'Service Quality',
      value: '4.8/5',
      trend: 'Provider Rating Target'
    },
    {
      title: 'Response Time',
      value: '30 min',
      trend: 'Service Level Agreement'
    },
    {
      title: 'Customer Satisfaction',
      value: '90%',
      trend: 'Target Satisfaction Rate'
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <Target className="h-6 w-6 text-primary" />
        <h2 className="text-xl font-semibold text-gray-900">Market Size Analysis</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {Object.entries(marketData).map(([key, { value, description }]) => (
          <div key={key} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 uppercase">{key.toUpperCase()}</h3>
            <p className="text-2xl font-bold text-gray-900 mb-1">{value}</p>
            <p className="text-sm text-gray-600">{description}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {marketInsights.map((insight) => (
          <div key={insight.title} className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-1">{insight.title}</h3>
            <p className="text-2xl font-bold text-gray-900">{insight.value}</p>
            <p className="text-sm text-gray-600">{insight.trend}</p>
          </div>
        ))}
      </div>
    </div>
  );
}