import { create } from 'zustand';
import { AppState, User, Permission } from './types';
import { initialTasks, initialMilestones } from './data/tasks';
import { supabase } from './supabaseClient';

const defaultUser: User = {
  email: '',
  isAuthenticated: false,
  name: '',
  status: 'pending'
};

const defaultColumns = {
  todo: {
    id: 'todo',
    title: 'To Do',
    taskIds: []
  },
  'in-progress': {
    id: 'in-progress',
    title: 'In Progress',
    taskIds: []
  },
  done: {
    id: 'done',
    title: 'Done',
    taskIds: []
  }
};

const defaultColumnOrder = ['todo', 'in-progress', 'done'];

export const useStore = create<AppState>((set, get) => ({
  tasks: initialTasks,
  milestones: initialMilestones,
  user: defaultUser,
  selectedTask: null,
  selectedMilestone: null,
  locale: 'en',
  language: localStorage.getItem('preferredLanguage') || 'en',
  columns: defaultColumns,
  columnOrder: defaultColumnOrder,

  setUser: (user: User) => set({ user }),
  setLocale: (locale: string) => set({ locale }),
  setLanguage: (language: string) => set({ language }),
  setSelectedTask: (id) => set({ selectedTask: id }),
  setSelectedMilestone: (id) => set({ selectedMilestone: id }),

  hasPermission: (permission: Permission) => {
    const { user } = get();
    return user.roles?.some(role => 
      role.permissions.some(p => 
        p.resource === permission.resource && 
        p.actions.some(action => permission.actions.includes(action))
      )
    ) ?? false;
  },

  addTask: (task) => set((state) => ({
    tasks: [...state.tasks, { ...task, id: `task-${Date.now()}` }]
  })),

  updateTask: (id, updates) => set((state) => ({
    tasks: state.tasks.map(task => 
      task.id === id ? { ...task, ...updates } : task
    )
  })),

  toggleTask: (id) => set((state) => ({
    tasks: state.tasks.map(task =>
      task.id === id ? { ...task, completed: !task.completed } : task
    )
  })),

  addMilestone: (milestone) => set((state) => ({
    milestones: [...state.milestones, { ...milestone, id: `milestone-${Date.now()}` }]
  })),

  updateMilestone: (id, updates) => set((state) => ({
    milestones: state.milestones.map(milestone =>
      milestone.id === id ? { ...milestone, ...updates } : milestone
    )
  })),

  moveTask: (taskId, _, destinationId) => set((state) => {
    const task = state.tasks.find(t => t.id === taskId);
    if (!task) return state;

    return {
      tasks: state.tasks.map(t => 
        t.id === taskId ? { ...t, milestone: destinationId } : t
      )
    };
  }),

  logout: async () => {
    await supabase.auth.signOut();
    set({
      user: defaultUser,
      selectedTask: null,
      selectedMilestone: null
    });
  }
}));